import {
  Button,
  Divider,
  Modal,
  IModalProps,
  Stack,
  ITextProps,
  IStackProps,
  HStack,
  IButtonProps,
} from 'native-base';
import React from 'react';

import { CloseIconButton, ModalHeader } from '@/components/Elements';

export const ContentStack = ({ ...props }: IStackProps) => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      bgColor="white"
      w="100%"
      h="100%"
      space={{ base: 4, md: 6 }}
      {...props}
    />
  );
};

export interface PopUpProps extends IModalProps {
  heading?: string;
  description?: ITextProps['children'];
  confirmBtnText?: IButtonProps['children'];
  backBtnText?: IButtonProps['children'];
  onClose?: () => void;
  onConfirm?: () => void;
  onPressX?: () => void;
  showCloseButton?: boolean;
  showCrossButton?: boolean;
  isBackButtonDisabled?: boolean;
  isConfirmButtonDisabled?: boolean;
  isConfirmButtonLoading?: boolean;
  modalContentProps?: React.ComponentProps<typeof Modal.Content>;
  contentStackProps?: IStackProps;
  headingProps?: ITextProps;
  subHeadingProps?: ITextProps;
  confirmButtonProps?: IButtonProps;
  childSpacerSize?: number;
  contentScrollable?: boolean;
}

export const PortalPopUp = ({
  heading,
  description,
  confirmBtnText,
  backBtnText,
  onClose,
  onConfirm,
  onPressX,
  showCloseButton = true,
  showCrossButton = true,
  isBackButtonDisabled,
  isConfirmButtonDisabled,
  isConfirmButtonLoading,
  modalContentProps = {},
  contentStackProps = {},
  headingProps = {},
  subHeadingProps = {},
  confirmButtonProps,
  childSpacerSize = 4,
  contentScrollable = true,
  ...props
}: PopUpProps) => (
  <Modal {...props}>
    <Modal.Content p={{ base: 4, md: 6 }} bg="white" {...modalContentProps} w="100%">
      <ContentStack>
        <HStack w="100%">
          {showCrossButton ? (
            <CloseIconButton
              iconProps={{ color: 'black' }}
              onPress={onPressX !== undefined ? onPressX : onClose}
            />
          ) : null}
          <ModalHeader
            heading={heading}
            subHeading={description}
            contentStackProps={contentStackProps}
            headingProps={headingProps}
            subHeadingProps={subHeadingProps}
          />
        </HStack>

        {props.children ? <Divider bgColor="gallery" h="1px" /> : null}

        {props.children ? (
          <Modal.Body p="0px" w="100%" overflow={contentScrollable ? 'auto' : 'hidden'}>
            {props.children}
          </Modal.Body>
        ) : null}

        <Divider bgColor="gallery" h="1px" />

        <Modal.Footer bgColor="white" p="0px" borderTopStyle="none" w="100%">
          <Stack
            direction="row"
            w="100%"
            justifyContent={showCloseButton ? 'space-between' : 'center'}
          >
            {showCloseButton ? (
              <Button
                variant="inline"
                onPress={onClose}
                size="bodyMdToLg"
                isDisabled={isBackButtonDisabled}
              >
                {backBtnText || 'BACK'}
              </Button>
            ) : null}
            <Button
              variant="hugPrimary"
              w="100%"
              maxW={{ base: '160px', md: '200px' }}
              onPress={onConfirm}
              size="bodyMlToLg"
              isLoading={isConfirmButtonLoading}
              isDisabled={isConfirmButtonDisabled}
              {...confirmButtonProps}
            >
              {confirmBtnText || 'CONFIRM'}
            </Button>
          </Stack>
        </Modal.Footer>
      </ContentStack>
    </Modal.Content>
  </Modal>
);

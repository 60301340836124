import { useState } from 'react';

import { Pet, PetStatus, ProductType, SurveyTag, useCancelPet } from '@/api';
import { useAccount } from '@/hooks';
interface CancelSurveyData {
  pet: Pet;
  primaryAnswer: string;
  secondaryAnswer?: string;
  customerComment?: string;
  passedAway?: boolean;
}

/**
 * Provides an async function to create a survey response and cancel the pet's plan.
 * Also includes analytics for the user action.
 */
export const useAnswerSurveyAndCancelPlan = () => {
  const account = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: cancelPet } = useCancelPet();

  const answerSurveyAndCancelPlan = async ({
    pet,
    primaryAnswer,
    secondaryAnswer,
    customerComment,
    passedAway,
  }: CancelSurveyData) => {
    setIsLoading(true);

    const mealPetPlanProduct = pet.pet_plan?.products?.find(
      (product) => product.product_type === ProductType.MEAL
    );

    await cancelPet({
      accountId: account.id,
      petId: pet.id,
      petStatus: passedAway ? PetStatus.DECEASED_TO_NOT_LIST : undefined,
      primaryCancellationReason: primaryAnswer,
      secondaryCancellationReason: secondaryAnswer,
      cancellationReasonComments: customerComment,
      surveyTag: SurveyTag.PET_PLAN_CANCEL,
      petPlanProduct: mealPetPlanProduct?.id,
      productCode: mealPetPlanProduct?.code,
      petPlanProductCreatedAt: mealPetPlanProduct?.created,
      unitPrice: mealPetPlanProduct?.unit_price,
      petPlan: pet.pet_plan,
    });
    setIsLoading(false);
  };

  return { isLoading, answerSurveyAndCancelPlan };
};

import { useRecipes } from './useRecipes';

/**
 * Utility hook for querying all addons
 * @returns useQuery-style response data for snack and supplement recipes
 */
export const useAddonRecipes = (accountId?: string | undefined) => {
  const {
    isLoading: isLoadingSnacks,
    isSuccess: isSuccessSnacks,
    data: snacks,
  } = useRecipes({
    recipeType: 'snacks',
    accountId,
  });

  const {
    isLoading: isLoadingSupplements,
    isSuccess: isSuccessSupplements,
    data: supplements,
  } = useRecipes({
    recipeType: 'supplements',
    accountId,
  });

  const {
    isLoading: isLoadingDailyEssentials,
    isSuccess: isSuccessDailyEssentials,
    data: dailyEssentials,
  } = useRecipes({
    recipeType: 'daily_essentials',
    accountId,
  });

  return {
    isLoading: isLoadingSnacks || isLoadingSupplements || isLoadingDailyEssentials,
    isSuccess: isSuccessSnacks && isSuccessSupplements && isSuccessDailyEssentials,
    data:
      isSuccessSnacks && isSuccessSupplements && isSuccessDailyEssentials
        ? [...snacks, ...supplements, ...dailyEssentials]
        : undefined,
  };
};

import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect } from '@react-navigation/native';
import axios from 'axios';
import * as Linking from 'expo-linking';
import { ScrollView, Stack } from 'native-base';
import { useCallback, useEffect, useState } from 'react';

import { reset_password } from '@/api';
import { ToastType, displayToast } from '@/components/Elements';
import {
  PENDING_ACCOUNT_ERROR,
  PendingAccountView,
} from '@/components/Portal/Login/LoginErrorMessage';
import { CreatePasswordForm, CreatePasswordFormValues, PasswordError } from '@/components/forms';
import { RootScreenProps } from '@/types';

const { logger } = datadogLogs;

export default function ResetPassword({ navigation }: RootScreenProps) {
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const url = Linking.useURL();
  const [resetTokenId, setResetTokenId] = useState('');
  const [resetTokenKey, setResetTokenKey] = useState('');
  const [onSubmitError, setOnSubmitError] = useState<string | null>(null);

  useFocusEffect(
    useCallback(() => {
      window.Gladly?.init({ autoShowButton: true, appId: 'spotandtango.com' });
    }, [])
  );

  const handleSubmit = async (data: CreatePasswordFormValues) => {
    setOnSubmitError(null);
    if (!isReady) {
      logger.error('Reset tokens not found for reset flow');
      return;
    }

    try {
      setIsLoading(true);
      await reset_password({
        ...data,
        reset_token_id: resetTokenId,
        reset_token_key: resetTokenKey,
      });
      navigation.navigate('Portal');
      setIsLoading(false);
    } catch (error: any) {
      if (
        axios.isAxiosError(error) &&
        error?.response?.status === 403 &&
        error?.response?.data === PENDING_ACCOUNT_ERROR
      ) {
        setOnSubmitError(PENDING_ACCOUNT_ERROR);
      } else {
        logger.error('Reset password request error', error);
        const passwordError = error as PasswordError;
        const errorData = passwordError?.response?.data;
        if (errorData?.password) {
          displayToast({
            title: 'Please try a different password',
            message: errorData?.password[0] || 'An error occurred',
            type: ToastType.Error,
          });
        } else {
          displayToast({
            message:
              'We are unable to process your request at this time. Please try again later or contact care@spotandtango.com for further assistance.',
            type: ToastType.Error,
          });
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Explicit check for url route `/reset_password/{{reset_token_id}}/{{reset_token_key}}
    if (url?.includes('/reset_password')) {
      const resetTokenData = url?.split('/reset_password/')[1]?.split('/');
      if (!resetTokenData) {
        logger.error('Incorrect reset password URL', { url });
        navigation.push('Portal');
      }
      const [reset_token_id, reset_token_key] = resetTokenData;
      setResetTokenId(reset_token_id);
      setResetTokenKey(reset_token_key);
      setIsReady(true);
      setOnSubmitError(null);
    }
  }, [navigation, url]);

  return (
    <ScrollView w="100%" bg="white">
      <Stack alignSelf="center" p={{ base: 4, md: 12 }} w="100%" maxW="550px">
        <CreatePasswordForm
          isLoading={isLoading}
          subHeading="Create a password to access your account."
          buttonText="Reset Password"
          onSubmit={handleSubmit}
          onError={(error: any) => console.log(error)}
        />
        {onSubmitError ? (
          <Stack direction="row" flexWrap="wrap" justifyContent="start">
            <PendingAccountView />
          </Stack>
        ) : null}
      </Stack>
    </ScrollView>
  );
}

import {
  Modal,
  IModalProps,
  Heading,
  Stack,
  Button,
  Text,
  CloseIcon,
  ScrollView,
  TextArea,
  IconButton,
  Checkbox,
} from 'native-base';
import { useLayoutEffect, useState } from 'react';

import { shuffle } from '@/utils';

type SurveyOption = { value: string; label: string };
const SURVEY_OPTIONS: SurveyOption[] = [
  { value: 'amazon', label: 'Amazon' },
  { value: 'events', label: 'Events' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'friend_family', label: 'Family or Friend' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'mobile_game_app', label: 'Mobile Game App' },
  { value: 'pinterest', label: 'Pinterest' },
  { value: 'podcast', label: 'Podcast' },
  { value: 'postcards', label: 'Postcards' },
  { value: 'radio', label: 'Radio' },
  {
    value: 'review_content_rewards_sites',
    label: 'Review Sites / Blogs  (e.g. USA Today, Business Insider…etc.)',
  },
  { value: 'rewards_sites', label: 'Rewards Sites' },
  { value: 'search_engine', label: 'Search Engine (e.g. Google, Bing…etc.)' },
  { value: 'television', label: 'Streaming/TV (e.g. NBCU, CBS, Sports, Disney, RON…etc.)' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'twitter', label: 'X (Twitter)' },
  { value: 'vet_breeder_trainer_shelter', label: 'Vets / Breeders / Trainers / Shelters' },
  { value: 'youtube', label: 'YouTube' },
];

const OTHER_OPTION = { value: 'other', label: 'Other' };

export interface PostCheckoutSurveyModalProps extends Omit<IModalProps, 'children'> {
  onExit?: () => void;
  onSubmit?: ({ selectedValue, otherText }: { selectedValue: string; otherText: string }) => void;
  isLoading?: boolean;
}

export const PostCheckoutSurveyModal = ({
  onExit = () => {},
  onSubmit = () => {},
  isLoading = false,
  ...props
}: PostCheckoutSurveyModalProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
  const [surveyOptions, setSurveyOptions] = useState<SurveyOption[]>([]);
  const [otherText, setOtherText] = useState('');

  useLayoutEffect(() => {
    setSurveyOptions([...shuffle(SURVEY_OPTIONS), OTHER_OPTION]);
  }, []);

  const onChangeSelection = (values: string[]) => {
    setSelectedValue(values.pop());
  };

  return (
    <Modal py="8px" px="16px" {...props}>
      <Modal.Content
        borderRadius={0}
        borderColor="black"
        borderWidth="2px"
        bg="white"
        marginTop="16px"
        px={{ base: 0, lg: 2 }}
        marginBottom="auto"
        _web={{ minW: { base: '100%', lg: '820px' } }}
        display="flex"
      >
        <Stack
          height="100%"
          direction="column"
          py="24px"
          px={{ base: 4, lg: 12 }}
          bgColor="white"
          w="100%"
        >
          <Modal.Header
            p={0}
            mb={2}
            bgColor="white"
            w="100%"
            borderBottomColor="black"
            justifyContent="center"
          >
            <Heading size="bodyMdToTitleMd" fontWeight="bold" mb={5}>
              How did you hear about Spot & Tango?
            </Heading>
            <IconButton
              position="absolute"
              right="14px"
              p={0}
              top={2}
              _icon={{ w: 3, h: 3, color: 'black' }}
              size="sm"
              icon={<CloseIcon />}
              onPress={() => onExit()}
            />
          </Modal.Header>

          <Modal.Body py={3} px={0} w="100%" overflowY="auto">
            <ScrollView w="100%">
              <Checkbox.Group
                value={selectedValue ? [selectedValue] : []}
                accessibilityLabel="Survey answer"
                onChange={onChangeSelection}
              >
                {surveyOptions.map(({ value, label }, i) => (
                  <Stack key={i} w="100%" mb={i === surveyOptions.length - 1 ? 0 : 5}>
                    <Checkbox value={`${value}`} variant="blackBorder">
                      <Text fontSize="bodySmToMd" fontFamily="secondary" fontWeight="book">
                        {label}
                      </Text>
                    </Checkbox>
                  </Stack>
                ))}
              </Checkbox.Group>
              {selectedValue === 'other' ? (
                <Stack mt={5} mb={2}>
                  <TextArea
                    value={otherText}
                    onChangeText={setOtherText}
                    variant="helperText"
                    placeholder="Additional Details (optional)"
                    size="bodySm"
                    autoCompleteType="off"
                    textAlign="left"
                    totalLines={16}
                    minHeight="150px"
                    maxLength={255}
                    autoFocus
                  />
                </Stack>
              ) : null}
            </ScrollView>
          </Modal.Body>

          <Modal.Footer
            bgColor="white"
            p="0px"
            borderTopStyle="none"
            w="100%"
            display="flex"
            justifyContent="center"
          >
            <Button
              variant="primary"
              w={{ base: '100%', lg: '316px' }}
              onPress={() => onSubmit({ selectedValue: selectedValue as string, otherText })}
              isDisabled={!selectedValue || isLoading}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Stack>
      </Modal.Content>
    </Modal>
  );
};

import { datadogLogs } from '@datadog/browser-logs';
import { useContext } from 'react';

import { AxonContext } from '@/context/AxonContext';

const { logger } = datadogLogs;

export const useAxonTrack = () => {
  const context = useContext(AxonContext);

  if (!context) {
    logger.error('useAxonTrack must be used within an AxonProvider');
  }

  return context?.track;
};

import { range } from '@/utils';

export const zip_codes = [
  '96701',
  '96703',
  '96704',
  '96705',
  '96706',
  '96707',
  '96708',
  '96709',
  '96710',
  '96712',
  '96713',
  '96714',
  '96715',
  '96716',
  '96717',
  '96718',
  '96719',
  '96720',
  '96721',
  '96722',
  '96725',
  '96726',
  '96727',
  '96728',
  '96729',
  '96730',
  '96731',
  '96732',
  '96733',
  '96734',
  '96737',
  '96738',
  '96739',
  '96740',
  '96741',
  '96742',
  '96743',
  '96744',
  '96745',
  '96746',
  '96747',
  '96748',
  '96749',
  '96750',
  '96751',
  '96752',
  '96753',
  '96754',
  '96755',
  '96756',
  '96757',
  '96759',
  '96760',
  '96761',
  '96762',
  '96763',
  '96764',
  '96765',
  '96766',
  '96767',
  '96768',
  '96769',
  '96770',
  '96771',
  '96772',
  '96773',
  '96774',
  '96776',
  '96777',
  '96778',
  '96779',
  '96780',
  '96781',
  '96782',
  '96783',
  '96784',
  '96785',
  '96786',
  '96788',
  '96789',
  '96790',
  '96791',
  '96792',
  '96793',
  '96795',
  '96796',
  '96797',
  '96801',
  '96802',
  '96803',
  '96804',
  '96805',
  '96806',
  '96807',
  '96808',
  '96809',
  '96810',
  '96811',
  '96812',
  '96813',
  '96814',
  '96815',
  '96816',
  '96817',
  '96818',
  '96819',
  '96820',
  '96821',
  '96822',
  '96823',
  '96824',
  '96825',
  '96826',
  '96828',
  '96830',
  '96836',
  '96837',
  '96838',
  '96839',
  '96840',
  '96841',
  '96843',
  '96844',
  '96846',
  '96847',
  '96848',
  '96849',
  '96850',
  '96853',
  '96854',
  '96857',
  '96858',
  '96859',
  '96860',
  '96861',
  '96863',
  '99501',
  '99502',
  '99503',
  '99504',
  '99505',
  '99506',
  '99507',
  '99508',
  '99509',
  '99510',
  '99511',
  '99513',
  '99514',
  '99515',
  '99516',
  '99517',
  '99518',
  '99519',
  '99520',
  '99521',
  '99522',
  '99523',
  '99524',
  '99529',
  '99530',
  '99540',
  '99545',
  '99546',
  '99547',
  '99548',
  '99549',
  '99550',
  '99551',
  '99552',
  '99553',
  '99554',
  '99555',
  '99556',
  '99557',
  '99558',
  '99559',
  '99561',
  '99563',
  '99564',
  '99565',
  '99566',
  '99567',
  '99568',
  '99569',
  '99571',
  '99572',
  '99573',
  '99574',
  '99575',
  '99576',
  '99577',
  '99578',
  '99579',
  '99580',
  '99581',
  '99583',
  '99585',
  '99586',
  '99587',
  '99588',
  '99589',
  '99590',
  '99591',
  '99599',
  '99602',
  '99603',
  '99604',
  '99605',
  '99606',
  '99607',
  '99608',
  '99609',
  '99610',
  '99611',
  '99612',
  '99613',
  '99614',
  '99615',
  '99619',
  '99620',
  '99621',
  '99622',
  '99623',
  '99624',
  '99625',
  '99626',
  '99627',
  '99628',
  '99629',
  '99630',
  '99631',
  '99632',
  '99633',
  '99634',
  '99635',
  '99636',
  '99637',
  '99638',
  '99639',
  '99640',
  '99641',
  '99643',
  '99644',
  '99645',
  '99647',
  '99648',
  '99649',
  '99650',
  '99651',
  '99652',
  '99653',
  '99654',
  '99655',
  '99656',
  '99657',
  '99658',
  '99659',
  '99660',
  '99661',
  '99662',
  '99663',
  '99664',
  '99665',
  '99666',
  '99667',
  '99668',
  '99669',
  '99670',
  '99671',
  '99672',
  '99674',
  '99675',
  '99676',
  '99677',
  '99678',
  '99679',
  '99680',
  '99681',
  '99682',
  '99683',
  '99684',
  '99685',
  '99686',
  '99687',
  '99688',
  '99689',
  '99690',
  '99691',
  '99692',
  '99693',
  '99694',
  '99695',
  '99697',
  '99701',
  '99702',
  '99703',
  '99704',
  '99705',
  '99706',
  '99707',
  '99708',
  '99709',
  '99710',
  '99711',
  '99712',
  '99714',
  '99716',
  '99720',
  '99721',
  '99722',
  '99723',
  '99724',
  '99725',
  '99726',
  '99727',
  '99729',
  '99730',
  '99731',
  '99732',
  '99733',
  '99734',
  '99736',
  '99737',
  '99738',
  '99739',
  '99740',
  '99741',
  '99742',
  '99743',
  '99744',
  '99745',
  '99746',
  '99747',
  '99748',
  '99749',
  '99750',
  '99751',
  '99752',
  '99753',
  '99754',
  '99755',
  '99756',
  '99757',
  '99758',
  '99759',
  '99760',
  '99761',
  '99762',
  '99763',
  '99764',
  '99765',
  '99766',
  '99767',
  '99768',
  '99769',
  '99770',
  '99771',
  '99772',
  '99773',
  '99774',
  '99775',
  '99776',
  '99777',
  '99778',
  '99780',
  '99781',
  '99782',
  '99783',
  '99784',
  '99785',
  '99786',
  '99788',
  '99789',
  '99790',
  '99791',
  '99801',
  '99802',
  '99803',
  '99811',
  '99812',
  '99820',
  '99821',
  '99824',
  '99825',
  '99826',
  '99827',
  '99829',
  '99830',
  '99832',
  '99833',
  '99835',
  '99836',
  '99840',
  '99841',
  '99850',
  '99901',
  '99903',
  '99918',
  '99919',
  '99921',
  '99922',
  '99923',
  '99925',
  '99926',
  '99927',
  '99928',
  '99929',
  '99950',
  '96799', // American Samoa
  // Puerto Rico
  ...range(600, 799 + 1).map((n) => `00${n}`),
  ...range(900, 999 + 1).map((n) => `00${n}`),
  // USVI
  ...range(801, 851 + 1).map((n) => `00${n}`),
  // Guam
  ...range(96910, 96932 + 1).map((n) => `${n}`),
];

export default zip_codes;

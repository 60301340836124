import React from 'react';

import { CarouselItem, CarouselItemProps } from './CarouselItem';
import {
  CarouselItem as CarouselItem2,
  CarouselItemProps as CarouselItemV2Props,
} from './v2/CarouselItem';

type ProductListingItemProps =
  | CarouselItemProps
  | (CarouselItemV2Props & {
      ref?: React.RefObject<HTMLDivElement>;
      h?: string;
      maxW?: string;
      mb?: { base: number; lg: number };
    });

export const ProductListingItem = ({
  unit,
  title,
  price,
  quantity,
  image,
  hoverStateImage,
  onAdd,
  onPressImage,
  onEditQuantity,
  pressableProps,
  badgeMessage,
  isLongPressAvailable = true,
  ...props
}: ProductListingItemProps) => {
  const commonProps = {
    unit,
    title,
    onPressImage,
    price,
    quantity,
    image,
    hoverStateImage,
    isLongPressAvailable,
    badgeMessage,
    onAdd,
    onEditQuantity,
    pressableProps,
    ...props,
  };

  const isV2Eligible = localStorage.getItem('PRODUCT_PLATFORM_V2') === 'true';
  if (isV2Eligible) {
    return <CarouselItem2 {...commonProps} />;
  }

  return <CarouselItem {...commonProps} />;
};

export default ProductListingItem;

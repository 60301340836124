import { Badge, Divider, Heading, HStack, Image, Stack, Text } from 'native-base';
import { useEffect, useState } from 'react';

import { FormSubmitButton } from '../../FormSubmitButton';

import { PetStatus, RecipeType } from '@/api';
import { Avatar, PillMenu } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { capitalize, getPetPlanMeals, getRecipesProductImageSrc } from '@/utils';

export const ManageInactivePlans = ({ navigation }: any) => {
  const account = useAccount();

  const pets = account?.pets.filter((pet) =>
    [PetStatus.PAUSED, PetStatus.CANCELLED].includes(pet.status)
  );

  const [selectedPetId, setSelectedPetId] = useState<string | null>(null);
  const pet = pets.find((pet) => pet.id === selectedPetId);
  const petPlan = pet && account.petplans.find((petplan) => petplan.pet === pet.id);

  useEffect(() => {
    if (pets?.length) {
      setSelectedPetId((prevSelectedPetId) => prevSelectedPetId || pets[0].id);
    }
  }, [pets]);

  if (!petPlan) {
    return null;
  }

  const dogItems = pets.map((pet) => ({
    label: pet.name,
    value: pet.id,
  }));
  const meals = getPetPlanMeals(petPlan);
  // Note: Addons are excluded from reactivation for now

  const onReactivate = () => {
    navigation.navigate('ReactivatePlan', { petId: pet.id });
  };

  return (
    <Stack alignItems="center" flex={1} w="100%" px={4}>
      <Stack w={{ base: '100%', lg: '840px' }} justifyContent="space-between">
        <Stack>
          <PillMenu
            items={dogItems}
            selectedItem={selectedPetId}
            onPress={(value) => setSelectedPetId(value)}
            mb="24px"
          />
          <Stack alignItems="center" space={{ base: 4, lg: 6 }}>
            <Stack variant="card" w="100%" space={4}>
              <HStack justifyContent="space-between">
                <Avatar
                  petId={pet.id}
                  name={`${pet.name}'s Plan`}
                  nameProps={{
                    size: 'bodyMlToTitleSm',
                    fontWeight: 'bold',
                    color: 'sntGrey.primary',
                  }}
                  imageUrl={pet.image_url}
                  imageSize={{ base: 6, lg: '36px' }}
                  containerProps={{ direction: 'row', space: '8px' }}
                />
                <Badge variant="smallTabInactive">{capitalize(petPlan.status)}</Badge>
              </HStack>
              <Divider bg="gallery" />
              <Stack space={1}>
                <Heading size="bodyMdToLg" fontWeight="bold" color="sntGrey.primary">
                  Meals
                </Heading>
                {meals.map((meal, i) => (
                  <HStack key={i} py={2} space={6}>
                    <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
                      <Image
                        source={{ uri: getRecipesProductImageSrc(meal.recipes, false) }}
                        alt={meal.recipes.map((recipe) => recipe.name).join(', ')}
                        w="100%"
                        h="100%"
                        resizeMode="contain"
                        opacity={50}
                      />
                    </Stack>

                    <Stack flex={3} space={2}>
                      <HStack alignItems="center">
                        <Heading size="bodyMdToLg" fontWeight="bold" color="sntGrey.primary">
                          {meal.recipes[0].type === RecipeType.UNKIBBLE ? 'UnKibble' : 'Fresh'}
                        </Heading>
                      </HStack>
                      <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
                        {meal.recipes.map((recipe) => recipe.name).join(', ')}
                      </Text>
                    </Stack>
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack alignItems="center" pt="85px" pb={5} space={3}>
        <FormSubmitButton onPress={onReactivate}>Reactivate</FormSubmitButton>
        <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
          You can make edits to your plan after you reactivate. You will not be charged right away.
        </Text>
      </Stack>
    </Stack>
  );
};

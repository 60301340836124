import { ScrollView, Stack, useBreakpointValue } from 'native-base';
import { useRef } from 'react';

import { BannerCarousel } from './BannerCarousel';
import { ProductSection } from './ProductSection';
import { ShopAnchorBar } from './ShopAnchorBar';

import { useAddonRecipes } from '@/api';
import { useMerchandiseProducts } from '@/api/services/products';
import { MerchandiseProduct, RecipeFull, RecipeType } from '@/api/types';
import { Image } from '@/components/Elements';
import { Footer } from '@/components/Portal';
import { SHOW_DAILY_ESSENTIALS } from '@/config';
import { ASSETS_URL } from '@/constants';
import { useAccount, useIsMobile } from '@/hooks';

export type SectionContent = {
  items?: RecipeFull[] | MerchandiseProduct[];
  title: string;
  sectionHeaderRef?: React.RefObject<HTMLDivElement>;
  itemsRef?: React.RefObject<HTMLDivElement>;
  bannerRef?: { recipeId: RecipeFull['id']; ref: React.RefObject<HTMLDivElement> };
  bannerUri: string;
  iconUri: string;
  recipeType: RecipeType;
  subheading: string;
};

export const ShopTab = () => {
  const isMobile = useIsMobile();
  const account = useAccount();
  const { data: availableAddons, isLoading: isAddonRecipesLoading } = useAddonRecipes(account?.id);
  const { data: merchProducts, isLoading: isMerchProductsLoading } = useMerchandiseProducts(
    account?.id
  );

  const snacksHeaderRef = useRef<HTMLDivElement>(null);
  const supplementsHeaderRef = useRef<HTMLDivElement>(null);
  const dailyEssentialsHeaderRef = useRef<HTMLDivElement>(null);
  const merchHeaderRef = useRef<HTMLDivElement>(null);

  const snackItemsRef = useRef<HTMLDivElement>(null);
  const supplementItemsRef = useRef<HTMLDivElement>(null);
  const dailyEssentialsItemsRef = useRef<HTMLDivElement>(null);
  const merchItemsRef = useRef<HTMLDivElement>(null);

  const calmingSuppRef = useRef<HTMLDivElement>(null);
  const beefTrainerRef = useRef<HTMLDivElement>(null);

  const bannersBaseUri = `${ASSETS_URL}/banners/shop-`;
  const iconsBaseUri = `${ASSETS_URL}/pictos/Icons/`;
  const mobileBannerContent = [
    { uri: `${bannersBaseUri}mobile-1.png` },
    { uri: `${bannersBaseUri}mobile-2.png`, ref: calmingSuppRef },
    { uri: `${bannersBaseUri}mobile-3.png`, ref: beefTrainerRef },
  ];
  const sectionList: SectionContent[] = [
    {
      items: availableAddons?.filter((recipe) => recipe.type === RecipeType.SNACK),
      title: 'Snacks',
      sectionHeaderRef: snacksHeaderRef,
      itemsRef: snackItemsRef,
      bannerRef: { recipeId: 'SN-BEEFTRAINERS', ref: beefTrainerRef },
      bannerUri: `${bannersBaseUri}snacks-${isMobile ? 'mobile' : 'desktop'}.png`,
      iconUri: `${iconsBaseUri}shop-snack.png`,
      recipeType: RecipeType.SNACK,
      subheading:
        "It's Snack time! Our single-ingredient Snacks are the perfect treat for dogs of all ages. Add a healthy, delicious, easy-to-digest Snack to your next box!",
    },
    {
      items: availableAddons?.filter((recipe) => recipe.type === RecipeType.SUPPLEMENT),
      title: 'Supplements',
      sectionHeaderRef: supplementsHeaderRef,
      itemsRef: supplementItemsRef,
      bannerRef: { recipeId: 'SUPP-C', ref: calmingSuppRef },
      bannerUri: `${bannersBaseUri}supplements-${isMobile ? 'mobile' : 'desktop'}.png`,
      iconUri: `${iconsBaseUri}shop-supplement.png`,
      recipeType: RecipeType.SUPPLEMENT,
      subheading:
        "Our vet-developed Supplements are made with the optimal dosage of active ingredients. Give your dog extra support where it's needed, daily.",
    },
    {
      items: merchProducts,
      title: 'Merch',
      sectionHeaderRef: merchHeaderRef,
      itemsRef: merchItemsRef,
      bannerUri: `${bannersBaseUri}merchandise-${isMobile ? 'mobile' : 'desktop'}.png`,
      iconUri: `${iconsBaseUri}shop-merch.png`,
      recipeType: RecipeType.MERCH,
      subheading:
        "It's not *all* about your dog. Just kidding- it is. Celebrate your dog and their favorite food (us!) with exclusive merch!\n\nPlease note: all merch items are final sale.",
    },
  ];

  const dailyEssentialsSectionData = {
    items: availableAddons?.filter((recipe) => recipe.type === RecipeType.DC),
    title: 'Daily Essentials',
    sectionHeaderRef: dailyEssentialsHeaderRef,
    itemsRef: dailyEssentialsItemsRef,
    bannerUri: `${bannersBaseUri}dc-${isMobile ? 'mobile' : 'desktop'}.webp`,
    iconUri: `${iconsBaseUri}shop-dc.webp`,
    recipeType: RecipeType.DC,
    subheading: 'Everyday staples, thoughtfully designed to make dog parenting easier.',
  };

  if (SHOW_DAILY_ESSENTIALS === 'true') {
    sectionList.splice(2, 0, dailyEssentialsSectionData);
  }

  const stickyHeaderIndex = useBreakpointValue({ base: 0, lg: undefined });

  const scrollViewDirection = useBreakpointValue({ base: 'column', lg: 'row' });

  return (
    <Stack w="100%" h="100%" alignItems="center" alignSelf="center">
      <ScrollView
        style={{ width: '100%' }}
        contentContainerStyle={{
          justifyContent: 'center',
          flexDirection: scrollViewDirection,
        }}
        stickyHeaderIndices={[stickyHeaderIndex]}
      >
        <ShopAnchorBar
          isLoading={isAddonRecipesLoading || isMerchProductsLoading}
          sectionList={sectionList}
        />
        <Stack
          space={{ base: 6, lg: 9 }}
          pr={{ base: 0, lg: 4 }}
          flex={1}
          maxW="1072px"
          mt={{ base: 6, lg: 0 }}
          mb={{ base: '524px', lg: '478px' }}
          pt={{ base: 0, lg: 12 }}
        >
          {isMobile ? (
            <BannerCarousel bannerContent={mobileBannerContent} />
          ) : (
            <Stack
              borderRadius="8px"
              overflow="hidden"
              w="100%"
              h={{ base: '140px', lg: '320px' }}
              mt={{ base: 6, lg: 0 }}
            >
              <Image
                w="100%"
                h="100%"
                resizeMode="cover"
                source={{ uri: `${ASSETS_URL}/banners/shop-desktop.png` }}
              />
            </Stack>
          )}

          <Stack w="100%" px={{ base: 4, lg: 0 }}>
            {sectionList.map((section, i) => {
              return section.items?.length ? (
                <ProductSection
                  key={i}
                  isLoading={isAddonRecipesLoading || isMerchProductsLoading}
                  sectionData={section}
                />
              ) : null;
            })}
          </Stack>
        </Stack>
        <Footer position="absolute" bottom={0} left={0} />
      </ScrollView>
    </Stack>
  );
};

import {
  Heading,
  Text,
  Factory,
  Pressable,
  VStack,
  IImageProps,
  Badge,
  IHeadingProps,
  Button,
  HStack,
  IPressableProps,
  IBadgeProps,
  ITextProps,
  Stack,
} from 'native-base';
import { forwardRef, useState } from 'react';

import { Image, PortalPopUp, TapForDetailsBadge } from '@/components/Elements';
import { InfoCta } from '@/components/Icons';
import SvgSubscribe from '@/components/Icons/Subscribe';
import { roundPriceShort } from '@/utils';

export type CarouselItemProps = {
  title: string;
  image: string;
  description?: string;
  price?: number | string;
  oneTimePrice?: number | string;
  retailPrice?: number | string;
  unit?: string;
  quantity?: number | string;
  onAdd?: () => void;
  onRemove?: () => void;
  onPressImage?: () => void;
  onEditQuantity?: () => void;
  isLongPressAvailable?: boolean;
  hoverStateImage?: string;
  badgeMessage?: IBadgeProps['children'];
  titleProps?: IHeadingProps;
  descriptionProps?: ITextProps;
  imageProps?: IImageProps;
  pressableProps?: IPressableProps;
  productType?: 'merch';
  showTapForDetails?: boolean;
};

export const MyCarouselItem = forwardRef<HTMLDivElement, CarouselItemProps>(
  (
    {
      title,
      image,
      description,
      price,
      oneTimePrice,
      retailPrice,
      unit,
      quantity,
      isLongPressAvailable = false,
      hoverStateImage = '',
      badgeMessage,
      onAdd,
      onRemove = () => {},
      onPressImage,
      onEditQuantity,
      imageProps,
      pressableProps,
      titleProps,
      descriptionProps,
      productType,
      showTapForDetails = false,
      ...props
    },
    ref
  ) => {
    const [showHoverStateImage, setShowHoverStateImage] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isProductTypeMerch = productType === 'merch';

    const toggleHoverImage = () =>
      isLongPressAvailable ? setShowHoverStateImage((prev) => !prev) : null;
    const quantityTextStyle = {
      fontFamily: 'secondary',
      size: 'bodySmToMd',
      color: 'sntGrey.primary',
      fontWeight: 'bold',
    };

    const handleInfoCtaClick = () => {
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const PricingOptions = () => {
      if (!price) return null;

      if (isProductTypeMerch) {
        return (
          <HStack alignItems="center">
            <Text size="bodyMdToLg" fontWeight="bold" color="black">
              {`$${roundPriceShort(price)}`}
            </Text>
          </HStack>
        );
      }

      return (
        <>
          <HStack alignItems="center" space={1}>
            <Text size="bodyMdToLg" fontWeight={700} color="black">
              {`$${roundPriceShort(oneTimePrice ?? 0)}${unit ? '/' + unit : ''}`}
            </Text>
            <Text size="bodyMdToLg" color="sntGrey.primary">
              |
            </Text>
            <Text size="bodySmToMd" fontWeight={400} color="sntGrey.primary">
              <Text textDecorationLine="line-through">
                {`$${roundPriceShort(retailPrice ?? 0)}`}
              </Text>{' '}
              retail price
            </Text>
            <Pressable>
              <InfoCta color="black" size="xs" onClick={handleInfoCtaClick} />
            </Pressable>
          </HStack>

          <HStack alignItems="center" space={1}>
            <Text size="bodyMdToLg" fontWeight={700} color="sntSubscriptionPricing">
              {`$${roundPriceShort(price)}${unit ? '/' + unit : ''}`}
            </Text>
            <HStack alignItems="center">
              <SvgSubscribe size="l" />
              <Text size="bodySmToMd" color="black" fontWeight={500}>
                Subscribe & Save
              </Text>
            </HStack>
          </HStack>
        </>
      );
    };

    return (
      <Stack>
        <VStack
          ref={ref}
          justifyContent="start"
          alignItems="center"
          h="100%"
          space={{ base: '8px', lg: '16px' }}
          maxW={{ lg: '265px' }}
          {...props}
        >
          <Pressable
            accessibilityLabel={`Press to view details on ${title}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderWidth="0px"
            _hover={{ borderWidth: '0px' }}
            p="0px"
            h="fit-content"
            onPress={() => onPressImage?.()}
            onLongPress={toggleHoverImage}
            onHoverIn={toggleHoverImage}
            onHoverOut={toggleHoverImage}
            {...pressableProps}
          >
            {badgeMessage ? (
              <Badge
                variant="smallTabActive"
                pointerEvents="none"
                position="absolute"
                zIndex={1}
                top={2}
                right={2}
                px={1}
              >
                {badgeMessage}
              </Badge>
            ) : null}
            {showTapForDetails && (
              <TapForDetailsBadge
                position="absolute"
                zIndex={1}
                bottom={2}
                left={2}
                px={1}
                size={{ base: '86px', lg: '93px' }}
                pointerEvents="none"
              />
            )}
            <Image
              alt=""
              size="265px"
              source={{
                uri: isLongPressAvailable && showHoverStateImage ? hoverStateImage : image,
              }}
              resizeMode="contain"
              bg="sntGrey.athens"
              {...imageProps}
            />
          </Pressable>
          <VStack
            justifyContent="center"
            alignItems="center"
            w="100%"
            space={2}
            onClick={() => onPressImage?.()}
          >
            <Heading size="bodyLg" fontWeight="bold" textAlign="center" {...titleProps}>
              {title}
            </Heading>
            <PricingOptions />
            {quantity && Number(quantity) > 0 ? (
              <HStack>
                <Text {...quantityTextStyle}>{quantity} added</Text>
                {onEditQuantity ? (
                  <Text {...quantityTextStyle}>
                    {' '}
                    -{' '}
                    <Button
                      _text={{
                        fontFamily: 'secondary',
                        size: 'bodySmToMd',
                        color: 'sntGrey.primary',
                      }}
                      variant="underlineMini"
                      onPress={onEditQuantity}
                    >
                      Edit
                    </Button>
                  </Text>
                ) : null}
              </HStack>
            ) : null}
          </VStack>
        </VStack>
        <PortalPopUp
          isOpen={isModalOpen}
          onClose={() => closeModal()}
          heading="Spot & Tango Exclusive Pricing"
          description="The retail price is what you would pay if you were shopping anywhere else, but as a Spot & Tango subscriber, you unlock exclusive discounts across our wide range of products."
          children={null}
          onConfirm={() => closeModal()}
          confirmBtnText="GOT IT!"
          showCloseButton={false}
          size={{ base: 'fit-content', md: '440px' }}
          marginY="auto"
          alignSelf="center"
          useRNModal
          subHeadingProps={{ fontFamily: 'primary', fontWeight: 'bold', size: 'bodySmToMd' }}
          headingProps={{ size: 'bodyMlToTitleSm' }}
          contentStackProps={{ px: { base: 4, md: 6 } }}
        />
      </Stack>
    );
  }
);

export const CarouselItem = Factory(MyCarouselItem);

import { useMutation } from 'react-query';

import { PetPlan, PetStatus, SurveyTag } from '@/api/types';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

interface CancelPetParams {
  accountId: string;
  petId: string;
  petStatus?: PetStatus;
  primaryCancellationReason?: string;
  secondaryCancellationReason?: string;
  cancellationReasonComments?: string;
  surveyTag?: SurveyTag;
  petPlanProduct?: string;
  productCode?: string;
  petPlanProductCreatedAt?: string;
  unitPrice?: string;
  petPlan?: PetPlan;
}

const cancelPet = async ({
  accountId,
  petId,
  petStatus,
  primaryCancellationReason,
  secondaryCancellationReason,
  cancellationReasonComments,
  surveyTag,
  petPlanProduct,
  productCode,
  petPlanProductCreatedAt,
  unitPrice,
  petPlan,
}: CancelPetParams) =>
  axios
    .post(`/pets/${petId}/cancel`, {
      account_id: accountId,
      pet_status: petStatus,
      survey_tag: surveyTag,
      pet_plan_product: petPlanProduct,
      product_code: productCode,
      pet_plan_product_created_at: petPlanProductCreatedAt,
      unit_price: unitPrice,
      primary_answer: primaryCancellationReason,
      secondary_answer: secondaryCancellationReason,
      customer_comments: cancellationReasonComments,
      pet_plan: petPlan?.id,
    })
    .then((response) => response.data);

export const useCancelPet = () => {
  const { refetchUser } = useAuth();

  return useMutation(
    ({
      accountId,
      petId,
      petStatus,
      primaryCancellationReason,
      secondaryCancellationReason,
      cancellationReasonComments,
      surveyTag,
      petPlanProduct,
      productCode,
      petPlanProductCreatedAt,
      unitPrice,
      petPlan,
    }: CancelPetParams) =>
      cancelPet({
        accountId,
        petId,
        petStatus,
        primaryCancellationReason,
        secondaryCancellationReason,
        cancellationReasonComments,
        surveyTag,
        petPlanProduct,
        productCode,
        petPlanProductCreatedAt,
        unitPrice,
        petPlan,
      }),
    {
      onSuccess: () => refetchUser(),
    }
  );
};

import { axios } from '@/lib/axios';

type AnonymousUserParams = {
  anonymous_user_id?: string;
  first_landing_page?: string;
};

type AnonymousUserResponse = {
  id?: string;
  treatments?: string[];
};

export const get_or_create_anonymous_user = async (
  data: AnonymousUserParams
): Promise<AnonymousUserResponse> =>
  await axios
    .post(`/anonymous_user/get_or_create`, data, { withCredentials: true, noErrorToast: true })
    .then((res) => res.data);

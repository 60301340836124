import { VStack, Image, IconButton, IIconButtonProps, Skeleton } from 'native-base';
import { useState, useEffect } from 'react';
import { PencilIcon } from 'react-native-heroicons/solid';

import { AvatarImageUploadModal } from '../Modal';

import { Pet, PetStatus } from '@/api';
import { ASSETS_URL } from '@/constants';

export type AvatarImageLargeProps = {
  petId: string;
  petName?: string;
  petImageUrl?: string | null;
  petGender?: Pet['gender'];
  petStatus?: PetStatus;
  grayscale?: boolean;
  iconButtonProps?: IIconButtonProps;
};

export const AvatarImageLarge = ({
  petId,
  petGender,
  petImageUrl,
  iconButtonProps,
  grayscale = false,
}: AvatarImageLargeProps) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (petImageUrl) {
      setIsLoading(true);
      const img = document.createElement('img') as HTMLImageElement;
      img.onload = () => setIsLoading(false);
      img.onerror = () => setIsLoading(false);
      img.src = petImageUrl;
    }
  }, [petImageUrl]);

  const [imageUploadModalIsOpen, setImageUploadModalIsOpen] = useState(false);

  const noAvatarImage = petGender === 'MALE' ? 'BoyDog.png' : 'GirlDog.png';
  const grayscaleProps = grayscale
    ? {
        tintColor: 'grey.secondary',
        opacity: 0.5,
        position: 'absolute' as 'absolute' | 'static' | 'relative' | 'fixed' | 'sticky',
      }
    : {};

  return (
    <VStack alignItems="center">
      <VStack
        bg={petImageUrl ? 'transparent' : `sntBlue.primary`}
        borderRadius="12px"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading ? (
          <Skeleton width={200} height={200} borderRadius="12px" />
        ) : (
          <Image
            source={{
              uri: petImageUrl ? `${petImageUrl}` : `${ASSETS_URL}/pictos/Gender/${noAvatarImage}`,
              cache: 'reload',
            }}
            alt="Pet profile image"
            width={200}
            height={200}
            resizeMode="contain"
            overflow="hidden"
            borderRadius="12px"
            {...grayscaleProps}
          />
        )}
        <IconButton
          bg="white"
          p={2}
          borderRadius="100%"
          borderColor="black"
          borderWidth={1}
          size="34px"
          position="absolute"
          bottom={-8}
          right={-8}
          style={{
            shadowColor: 'rgba(0,0,0,0.15)',
            shadowOffset: { width: 4, height: 4 },
            shadowRadius: 4,
          }}
          onPress={() => setImageUploadModalIsOpen(true)}
          icon={<PencilIcon />}
          {...iconButtonProps}
          zIndex={1}
        />
      </VStack>
      <AvatarImageUploadModal
        petImageUrl={petImageUrl}
        petId={petId}
        isOpen={imageUploadModalIsOpen}
        onClose={() => setImageUploadModalIsOpen(false)}
      />
    </VStack>
  );
};

import { datadogLogs } from '@datadog/browser-logs';
import { Divider, IStackProps, Stack } from 'native-base';
import React from 'react';

import { displayToast, ToastType } from '../Toast';
import { LineItemOneTimeCardHeading } from './LineItemOneTimeCardHeading';
import { LineItemPlanCardProps } from './LineItemPlanCard';
import { LineItemPlanCardExtra } from './LineItemPlanCardExtra';
import { LineItemPlanCardMeal } from './LineItemPlanCardMeal';
import { LineItemPlanCardSnackOrSupplement } from './LineItemPlanCardSnackOrSupplement';

import { useUpdateOrderProducts } from '@/api';
import { useAuth } from '@/hooks';
import {
  addonSeqNoCompare,
  extrasOrderProductCategorySort,
  mealOrderProductCategorySort,
} from '@/utils';

const { logger } = datadogLogs;

export interface LineItemOneTimeCardProps extends IStackProps {
  dogPlans: LineItemPlanCardProps['content'][];
}

export const LineItemOneTimeCard = ({ dogPlans }: LineItemOneTimeCardProps) => {
  const { refetchUser } = useAuth();
  const { mutateAsync: updateOrderProducts, isLoading: isLoadingUpdateProducts } =
    useUpdateOrderProducts();

  const handleOneTimeProductStepper = async (
    productCode: string,
    productName: string,
    mode: 'increase' | 'decrease'
  ) => {
    const successVerb = mode === 'increase' ? 'added' : 'removed';
    const errorVerb = mode === 'increase' ? 'adding' : 'removing';
    const preposition = mode === 'increase' ? 'to' : 'from';
    try {
      await updateOrderProducts({
        productCode,
        mode,
      });
      await refetchUser();

      displayToast({
        message: `We've ${successVerb} ${productName} ${preposition} your next order.`,
        type: ToastType.Success,
      });
    } catch (err) {
      displayToast({
        message: `An error occurred while ${errorVerb} ${productName} ${preposition} your next order.`,
        type: ToastType.Error,
      });
      logger.error(
        `Error ${errorVerb} ${productName} ${preposition} order ID ${dogPlans[0].orderId}`
      );
    }
  };

  const meals = dogPlans.flatMap((dogPlan) => dogPlan.meals);
  const snacks = dogPlans.flatMap((dogPlan) => dogPlan.snacks);
  const supplements = dogPlans.flatMap((dogPlan) => dogPlan.supplements);
  const dailyEssentials = dogPlans.flatMap((dogPlan) => dogPlan.dailyEssentials);
  const extras = dogPlans.flatMap((dogPlan) => dogPlan.extras);

  return (
    <Stack
      p={{ base: 4, lg: 6 }}
      borderWidth={1}
      borderColor="sntGrey.outline"
      w="100%"
      space={{ base: 4, lg: 6 }}
    >
      <LineItemOneTimeCardHeading
        dog={dogPlans[0]?.dog}
        orderEditable={dogPlans[0].orderEditable}
      />

      {/* Display all one time extras */}
      {extras.length > 0 ? (
        <Stack space={{ base: 4, lg: 6 }}>
          <Divider bg="gallery" />
          <Stack space={{ base: 4, lg: 6 }}>
            {extras.sort(extrasOrderProductCategorySort).map((extra, i, arr) => (
              <LineItemPlanCardExtra
                product={extra}
                key={extra.id}
                disabled={false}
                showDivider={i < arr.length - 1}
                showStepper={dogPlans[0].orderEditable}
                handlePressStepper={handleOneTimeProductStepper}
                isLoading={isLoadingUpdateProducts}
              />
            ))}
          </Stack>
        </Stack>
      ) : null}

      {/* Display all one time snacks */}
      <Stack space={{ base: 4, lg: 6 }}>
        {snacks.length ? (
          <Stack space={{ base: 4, lg: 6 }}>
            {!extras.length ? (
              <Divider bg="gallery" />
            ) : (
              <Divider bg="gallery" w="85%" alignSelf="center" />
            )}
            <Stack space={{ base: 4, lg: 6 }}>
              {snacks.sort(addonSeqNoCompare).map((snack, i, arr) => (
                <LineItemPlanCardSnackOrSupplement
                  product={snack}
                  key={snack.id}
                  showDivider={i < arr.length - 1}
                  variant="one-time"
                  handlePressStepper={handleOneTimeProductStepper}
                  isLoading={isLoadingUpdateProducts}
                  showStepper={dogPlans[0].orderEditable}
                />
              ))}
            </Stack>
          </Stack>
        ) : null}

        {/* Display all one time supplements */}
        {supplements.length ? (
          <Stack space={{ base: 4, lg: 6 }}>
            {!extras.length && !snacks.length ? (
              <Divider bg="gallery" />
            ) : (
              <Divider bg="gallery" w="85%" alignSelf="center" />
            )}
            <Stack space={{ base: 4, lg: 6 }}>
              {supplements.sort(addonSeqNoCompare).map((supplement, i, arr) => (
                <LineItemPlanCardSnackOrSupplement
                  product={supplement}
                  key={supplement.id}
                  showDivider={i < arr.length - 1}
                  variant="one-time"
                  handlePressStepper={handleOneTimeProductStepper}
                  isLoading={isLoadingUpdateProducts}
                  showStepper={dogPlans[0].orderEditable}
                />
              ))}
            </Stack>
          </Stack>
        ) : null}

        {/* Daily Essentials */}
        {dailyEssentials.length ? (
          <Stack space={{ base: 4, lg: 6 }}>
            {!extras.length && !snacks.length && !supplements.length ? (
              <Divider bg="gallery" />
            ) : (
              <Divider bg="gallery" w="85%" alignSelf="center" />
            )}
            <Stack space={{ base: 4, lg: 6 }}>
              {dailyEssentials.sort(addonSeqNoCompare).map((dailyEssential, i, arr) => (
                <LineItemPlanCardSnackOrSupplement
                  product={dailyEssential}
                  key={dailyEssential.id}
                  showDivider={i < arr.length - 1}
                  variant="one-time"
                  handlePressStepper={handleOneTimeProductStepper}
                  isLoading={isLoadingUpdateProducts}
                  showStepper={dogPlans[0].orderEditable}
                />
              ))}
            </Stack>
          </Stack>
        ) : null}

        {/* Display all one time Meals */}
        {meals.length > 0 && (
          <>
            {!extras.length && !snacks.length && !supplements.length && !dailyEssentials.length ? (
              <Divider bg="gallery" />
            ) : (
              <Divider bg="gallery" w="85%" alignSelf="center" />
            )}
            <LineItemPlanCardMeal
              meals={meals.sort(mealOrderProductCategorySort)}
              frequency={dogPlans[0].frequency}
              disabled={false}
              variant="one-time"
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

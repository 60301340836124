import { axios } from '@/lib/axios';

export const uploadImage = async ({
  petId,
  encodedImage,
}: {
  petId: string;
  encodedImage: string;
}) => {
  return axios
    .post(
      `/pets/${petId}/upload-image`,
      { encodedImage },
      {
        noErrorToast: true,
      }
    )
    .then((response) => response.data);
};

import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';

const deleteImage = async ({ petId }: { petId: string }) =>
  axios.delete(`/pets/${petId}/delete-image`).then((response) => response.data);

export const useDeleteImage = () => {
  return useMutation(({ petId }: { petId: string }) =>
    deleteImage({
      petId,
    })
  );
};

import { Stack, Heading, FlatList, HStack, Badge, Divider, VStack } from 'native-base';
import { useMemo } from 'react';

import { RecipeFull, RecipeType, useRecipes } from '@/api';
import { Stepper, Image, LoadingSpinner } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount } from '@/hooks';
import { capitalize, formatPrice } from '@/utils';

interface AddonLineItemProps {
  product: RecipeFull;
  showDivider?: boolean;
  disabledPlus?: boolean;
  disabledMinus?: boolean;
}

const AddonLineItem = ({
  product,
  showDivider,
  disabledPlus = false,
  disabledMinus = false,
}: AddonLineItemProps) => {
  const images = `${ASSETS_URL}/pictos/`;
  const assetsDirectory = product.type;

  const { snacks, supplements, saveAddOnQuantity } = useAddNewDogContext();

  const quantity = useMemo(
    () => (product.type === RecipeType.SNACK ? snacks[product.id] : supplements[product.id]) ?? 0,
    [product.id, product.type, snacks, supplements]
  );

  return (
    <HStack space={{ base: '8px', lg: '16px' }} w="100%">
      <Stack w={{ base: '64px', lg: '84px' }} h={{ base: '64px', lg: '84px' }}>
        <Image
          source={{
            uri: `${images}${capitalize(assetsDirectory)}/${product.id}.png`, // TODO
          }}
          size={{ base: '64px', lg: '84px' }}
          resizeMode="contain"
          alt={product.name} // TODO
        />
      </Stack>
      <HStack w="100%" h="100%" flex={1} justifyContent="space-between">
        <VStack space={2} flex={1}>
          <HStack flex={1} justifyContent="space-between" alignItems="center">
            <Heading size="bodyMdToLg" fontWeight="medium">
              {product.name}
            </Heading>

            {/* TODO */}
            {product ? (
              <Badge variant="smallInfoPillSecondary">
                {formatPrice(Number(product.discounted_price ?? product.price) * quantity, 'dash')}
              </Badge>
            ) : null}
          </HStack>

          <Stepper
            alignSelf="flex-start"
            maxW={{ base: '45%', lg: '33%' }}
            controlledQuantity={quantity}
            onPressDec={() => {
              saveAddOnQuantity({ id: product.id, quantity: quantity - 1 }, product.type);
            }}
            onPressInc={() => {
              saveAddOnQuantity({ id: product.id, quantity: quantity + 1 }, product.type);
            }}
            disabledPlus={disabledPlus}
            disabledMinus={disabledMinus}
          />
          {showDivider ? <Divider my={4} bg="gallery" /> : null}
        </VStack>
      </HStack>
    </HStack>
  );
};

export const AddonSection = () => {
  const account = useAccount();
  const { snacks, supplements, discountCode } = useAddNewDogContext();

  const { data: allSnacks, isLoading: isLoadingSnacks } = useRecipes({
    recipeType: 'snacks',
    accountId: account.id,
    discount: discountCode ?? undefined,
  });
  const { data: allSupplements, isLoading: isLoadingSupplements } = useRecipes({
    recipeType: 'supplements',
    accountId: account.id,
    discount: discountCode ?? undefined,
  });

  const chosenAddons = useMemo(() => {
    return [
      ...(allSnacks ?? []).filter((snack) => !!snacks[snack.id]),
      ...(allSupplements ?? []).filter((supp) => !!supplements[supp.id]),
    ];
  }, [allSnacks, allSupplements, snacks, supplements]);

  if (isLoadingSnacks || isLoadingSupplements) return <LoadingSpinner />;

  return (
    <Stack justifyContent="center" space={{ base: '16px', lg: '24px' }} w="100%">
      <Heading size="bodyMdToLg" fontWeight="bold">
        Extras
      </Heading>
      <FlatList
        data={chosenAddons}
        renderItem={({ item, index }) => {
          return <AddonLineItem product={item} showDivider={index < chosenAddons.length - 1} />;
        }}
        keyExtractor={(item) => item.id}
        w="100%"
      />
    </Stack>
  );
};

import { useIsFocused } from '@react-navigation/native';
import { Heading, Box, Stack, Pressable, Text } from 'native-base';
import { useState, useEffect } from 'react';

import { PetGender } from '@/api';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useFunnelErrorHandler } from '@/hooks';
import { useAxonTrack } from '@/hooks/useAxonTrack';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FunnelScreenProps } from '@/types';
import { Dog } from '@/types/FunnelSession';
import { getCurrentPet } from '@/utils/getCurrentPet';

export default function Neutered({ navigation }: FunnelScreenProps<'Neutered'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.NEUTERED);
  const { session, mutateUpdatePetAnswer } = useSessionQuery();
  const dog = getCurrentPet(session);
  const [isNeutered, setIsNeutered] = useState<Dog['is_neutered'] | undefined>();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { addAxiosPromise } = useAxiosStatusContext();
  const track = useAxonTrack();

  /**
   * When navigating back to a page, sets the currently viewed screen as "true".
   * used to get previously submitted data
   **/
  const isFocused = useIsFocused();

  useEffect(() => {
    track('page_view');
  }, []);

  useEffect(() => {
    if (!isFocused) return;

    setIsNeutered(dog?.is_neutered);
  }, [isFocused]);

  const onSubmit = async (is_neutered: boolean) => {
    setIsNeutered(is_neutered);
    try {
      addAxiosPromise(
        mutateUpdatePetAnswer({
          is_neutered,
        })
      );
    } catch (error) {
      funnelErrorHandler(error, 'Update is_neutered');
    }
    navigation.navigate(nextStep);
  };

  if (!isFocused || !dog || !Object.keys(dog).length) {
    return null;
  }

  return (
    <FunnelWrapper
      containerProps={{
        justifyContent: 'flex-start',
        space: { base: '16px', lg: '24px' },
      }}
    >
      <Heading size="titleSmToMd" fontWeight="bold">
        Is {dog?.name ? dog.name : 'your dog'}{' '}
        {dog?.gender === PetGender.FEMALE ? 'spayed' : 'neutered'}?
      </Heading>

      <Stack
        w="100%"
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Pressable
          variant="card"
          _hover={{ bg: 'white' }}
          w={{ base: '100%', md: '324px' }}
          onPress={() => onSubmit(true)}
          py={{ base: '42px', md: '36px' }}
          px="16px"
          isPressed={isNeutered === true}
          alignItems="center"
        >
          <Text size="titleSmToMd" fontWeight="bold">
            Yes
          </Text>
        </Pressable>
        <Box w={{ base: '16px', lg: '24px' }} h={{ base: '16px', lg: '24px' }} />
        <Pressable
          variant="card"
          _hover={{ bg: 'white' }}
          w={{ base: '100%', md: '324px' }}
          onPress={() => onSubmit(false)}
          py={{ base: '42px', md: '36px' }}
          px="16px"
          isPressed={isNeutered === false}
          alignItems="center"
        >
          <Text size="titleSmToMd" fontWeight="bold">
            No
          </Text>
        </Pressable>
      </Stack>
    </FunnelWrapper>
  );
}

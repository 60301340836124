import { Stack, HStack, Heading, Badge } from 'native-base';

import { PlanLineItem } from '../../PlanSummaryCard';

import { BuildPlan } from '@/api';
import { Dog } from '@/types';
import {
  formatPrice,
  getRecipeImageSrc,
  getMealRecipeName,
  capitalize,
  getRecipesProductImageSrc,
} from '@/utils';

interface MealSectionProps {
  petData: Partial<Dog>;
  petPlan: BuildPlan;
}

export const MealSection = ({ petData, petPlan }: MealSectionProps) => {
  const mealProductsPrice =
    petPlan.subscription.price.discounted_price_per_week ??
    petPlan.subscription.price.price_per_week;
  const mealRecipes = petPlan.subscription.product.recipes;
  const isTopper = petData.plan_type === 'topper';

  return (
    <Stack justifyContent="center" alignItems="start" space={{ base: '16px', lg: '24px' }} w="100%">
      <HStack w="100%" justifyContent="space-between">
        <Heading size="bodyMdToLg" fontWeight="bold">
          Meals
        </Heading>
        <Badge variant="smallInfoPillSecondary">
          {mealProductsPrice ? `${formatPrice(mealProductsPrice)}/wk` : null}
        </Badge>
      </HStack>
      <Stack w="100%" space={{ base: '16px', lg: '24px' }}>
        <PlanLineItem
          heading="Portion Size"
          description={`${petPlan.calories_per_day} cal/day`}
          imgSrc={getRecipeImageSrc(mealRecipes, 'PortionSize')}
          secondaryDescription={isTopper ? 'Mixing S&T' : 'Feeding 100% S&T'}
          chevron={false}
          pressableProps={{ disabled: true }}
        />
        <PlanLineItem
          heading="Food Type"
          description={getMealRecipeName(petPlan.recipe_type)}
          imgSrc={getRecipeImageSrc(mealRecipes, 'FoodType')}
          chevron={false}
          pressableProps={{ disabled: true }}
        />
        <PlanLineItem
          heading="Recipe"
          description={mealRecipes.map(({ name }) => capitalize(name)).join(', ')}
          imgSrc={getRecipesProductImageSrc(mealRecipes, false)}
          divider={false}
          chevron={false}
          pressableProps={{ disabled: true }}
        />
      </Stack>
    </Stack>
  );
};

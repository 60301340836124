import { datadogLogs } from '@datadog/browser-logs';
import React, { createContext, ReactNode } from 'react';

const { logger } = datadogLogs;

export type AxonContextType = {
  track: (eventName: string, eventData?: Record<string, any>) => void;
};

export const AxonContext = createContext<AxonContextType>({
  track: () => {},
});

export const AxonProvider = ({ children }: { children: ReactNode }) => {
  const track = (eventName: string, eventData?: Record<string, any>) => {
    if (!window.axon) {
      logger.warn('Axon not initialized');
      return;
    }

    try {
      window.axon('track', eventName, eventData);
    } catch (err) {
      logger.error(`Failed to track event with axon: ${err}`);
    }
  };

  return <AxonContext.Provider value={{ track }}>{children}</AxonContext.Provider>;
};

import { useNavigation, NavigationProp } from '@react-navigation/native';
import { Stack, Flex, Button, Text } from 'native-base';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { ProtectedStackParamList } from '@/types';

export const DeceasedDogsView = () => {
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();

  return (
    <Stack
      justifyContent={{ base: 'center' }}
      alignItems="center"
      alignSelf="center"
      w="100%"
      h="fit-content"
      pb={{ base: '16px', lg: '12px' }}
      px={{ base: '16px', lg: '0px' }}
      direction={{ base: 'column', md: 'column' }}
      space={{ base: '16px', lg: '24px' }}
    >
      <Flex w={{ base: '100%', md: '840px' }}>
        <Text fontWeight="bold">Welcome back!</Text>
      </Flex>
      <Flex
        w={{ base: '100%', md: '840px' }}
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
        borderColor="black"
        borderWidth="2px"
      >
        <Flex
          w="100%"
          h={{ base: '337px', md: '392px' }}
          p="24px"
          overflow="hidden"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            w="100%"
            h="100%"
            alt=""
            source={{ uri: `${ASSETS_URL}/pictos/MarketingCards/APRIL_ST_EVAN_08511.png` }}
            resizeMode="cover"
          />
        </Flex>
        <Button
          mb="24px"
          textTransform="uppercase"
          variant="hugSecondary"
          onPress={() => {
            navigation.navigate('AddNewDog');
          }}
          size="bodyMl"
        >
          Add a new dog
        </Button>
      </Flex>
    </Stack>
  );
};

import {
  Badge,
  Button,
  CloseIcon,
  Divider,
  Heading,
  HStack,
  IconButton,
  Modal,
  ScrollView,
  Spacer,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { useRef, useState } from 'react';

import { Pricing } from './Pricing';
import { getAccordionInfo } from './getAccordionInfo';

import { RecipeType } from '@/api/types/accountServices';
import {
  Accordion,
  Image,
  ResponsiveCarousel,
  ReviewCarousel,
  AccordionItem,
  PDPModalProps,
  RadioButtonCard,
} from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useBodyScrollLock } from '@/hooks';
import { capitalize, roundPriceShort } from '@/utils';

// We should consider using backend DB for these images...
function getPDPHeroImages(recipeType: RecipeType, id: string) {
  const imageExt = recipeType === RecipeType.DC ? 'webp' : 'png';
  return Array(5)
    .fill('')
    .map((_, i) => `${ASSETS_URL}/pictos/PDP/${capitalize(recipeType)}/${id}/${i}.${imageExt}`);
}

export interface ShopPDPModalProps extends PDPModalProps {
  productPrice?: {
    recurringPrice?: string;
    oneTimePrice?: string;
    retailPrice?: string;
  };
  handleAddRecurring?: () => void;
  handleAddOneTime?: () => void;
  buttonOneTimeText?: string;
  isLoading: boolean;
}

export const ShopPDPModal = ({
  recipe,
  prices = [],
  productPrice,
  discountedPrices = [],
  pricingUnits = [],
  isTrial = false,
  handleAddRecurring,
  handleAddOneTime,
  buttonText,
  buttonOneTimeText = 'ADD TO NEXT ORDER',
  isLoading = false,
  showCTA = false, // PDP modal CTAs are being hidden for now -- PRODUCT-1768
  ...props
}: ShopPDPModalProps) => {
  const { id, name, type, info_pills, description, reviews, product_contents } = recipe;
  const accordionInfo = getAccordionInfo(recipe);
  const [isSubscribeAndSaveSelected, setIsSubscribeAndSaveSelected] = useState(true);
  const [isOneTimeSelected, setIsOneTimeSelected] = useState(false);

  const initialRef = useRef(null);
  const reviewsWithRecipe = reviews
    .map((review) => ({
      ...review,
      recipe: { id, name, type },
    }))
    .sort((review1, review2) => review1.seq_no - review2.seq_no);

  const lockRef = useBodyScrollLock({ enabled: props.isOpen });

  const heroImages = getPDPHeroImages(type, id);
  const isV2Eligible = localStorage.getItem('PRODUCT_PLATFORM_V2') === 'true';

  const onPressV1 = () => {
    handleAddRecurring?.();
  };

  const onPressV2 = () => {
    isSubscribeAndSaveSelected && handleAddRecurring?.();
    isOneTimeSelected && handleAddOneTime?.();
    setIsOneTimeSelected(false);
    setIsSubscribeAndSaveSelected(true);
  };

  return (
    <Modal size="full" initialFocusRef={initialRef} useRNModal focusable {...props}>
      <Modal.Content
        bg="white"
        my={{ base: '5vh', lg: 4 }}
        minHeight={{ base: '85vh', lg: '95vh' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        w={{ base: '95vw', lg: '772px' }}
        borderWidth={{ base: '2px', lg: '3px' }}
      >
        <ScrollView ref={lockRef} style={{ width: '100%' }}>
          <IconButton
            position="sticky"
            top={4}
            right={8}
            zIndex={1000}
            alignSelf="flex-end"
            p="0px"
            onPress={() => {
              if (isV2Eligible) {
                setIsOneTimeSelected(false);
                setIsSubscribeAndSaveSelected(true);
              }
              props.onClose?.();
            }}
            icon={
              <CloseIcon
                alt="Close product detail page"
                color="black"
                size={{ base: '11px', md: '14px' }}
              />
            }
          />
          <VStack
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            w={{ base: '95vw', lg: '772px' }}
            p={{ base: 4, lg: 6 }}
            h="100%"
          >
            <ResponsiveCarousel
              variant="gallery"
              data={heroImages}
              itemWidth={{ base: 288, md: 484 }}
              width={{ base: 500, md: 772 }}
              height={{ base: 264, md: 460 }}
              disableLoop
              renderItem={({ item }) => {
                return (
                  <VStack
                    width="100%"
                    height="100%"
                    overflow="hidden"
                    justifyContent="center"
                    alignItems="center"
                    pr={{ base: '6px', md: '12px' }}
                    pl={{ base: '6px', md: '12px' }}
                  >
                    <Image
                      source={{ uri: item }}
                      alt={name + ' detail image'}
                      width="100%"
                      height="100%"
                      resizeMode="cover"
                    />
                  </VStack>
                );
              }}
            />
            <VStack space={{ base: 4, lg: 6 }} alignItems="center">
              <VStack space={{ base: 1, lg: 2 }} alignItems="center">
                <Heading size={{ base: 'bodyLg', lg: 'titleSm' }} fontWeight="bold">
                  {name}
                </Heading>
                {!isV2Eligible && (
                  <Stack space={1} alignItems="center">
                    {prices?.map((price, i) => (
                      <Pricing
                        key={`${price}${i}`}
                        itemType={type}
                        pricing={Number(price)}
                        pricingUnit={pricingUnits[i] || 'meal'}
                        isTrial={isTrial && i === 0}
                        discountedPrice={
                          i < discountedPrices.length && discountedPrices[i] !== null
                            ? Number(discountedPrices[i])
                            : null
                        }
                      />
                    )) || null}
                  </Stack>
                )}
                {product_contents?.[0]?.text ? (
                  <Text fontWeight="medium" fontSize="textSmToMd" fontFamily="secondary">
                    {product_contents[0].text}
                  </Text>
                ) : null}
              </VStack>
              <HStack justifyContent="center" alignItems="center">
                {info_pills.length > 0 &&
                  info_pills.map((obj, i, arr) => {
                    return (
                      <HStack
                        key={obj.slug + i.toString() + 'container'}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Badge
                          _text={{
                            fontSize: { base: '12px', lg: '14px' },
                            lineHeight: { base: '16px', lg: '18px' },
                          }}
                          key={obj.slug + i.toString()}
                          variant="smallInfoPill"
                        >
                          {obj.text}
                        </Badge>
                        {i < arr.length - 1 ? <Spacer size={{ base: 1, lg: 2 }} /> : null}
                      </HStack>
                    );
                  })}
              </HStack>
              <VStack w="100%" maxW="400px" px={{ base: 4, lg: 0 }}>
                <VStack space={{ base: 4, lg: 6 }}>
                  <Divider bg="gallery" />
                  {/* *************** OVERVIEW ********************* */}
                  <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
                    {description}
                  </Text>
                  {isV2Eligible && (
                    <VStack space={2}>
                      <RadioButtonCard
                        title="Subscribe & Save"
                        subtitle="Conveniently delivered with your dog’s meals"
                        price={
                          productPrice?.recurringPrice
                            ? `$${roundPriceShort(productPrice.recurringPrice)}/${
                                pricingUnits[0] || 'bag'
                              }`
                            : undefined
                        }
                        retailPrice={
                          productPrice?.retailPrice
                            ? `$${roundPriceShort(productPrice.retailPrice)}`
                            : undefined
                        }
                        cancelLabel="Cancel at any time"
                        isSubscriptionCard
                        selected={isSubscribeAndSaveSelected}
                        onCardPress={() => {
                          setIsOneTimeSelected(false);
                          setIsSubscribeAndSaveSelected(true);
                        }}
                      />
                      <RadioButtonCard
                        title="One-Time"
                        price={
                          productPrice?.oneTimePrice
                            ? `$${roundPriceShort(productPrice.oneTimePrice)}/${
                                pricingUnits[0] || 'bag'
                              }`
                            : undefined
                        }
                        retailPrice={
                          productPrice?.retailPrice
                            ? `$${roundPriceShort(productPrice.retailPrice)}`
                            : undefined
                        }
                        isSubscriptionCard={false}
                        selected={isOneTimeSelected}
                        onCardPress={() => {
                          setIsOneTimeSelected(true);
                          setIsSubscribeAndSaveSelected(false);
                        }}
                      />
                    </VStack>
                  )}
                  <Divider bg="gallery" />
                </VStack>
                {/* *************** ACCORDION ********************* */}
                {accordionInfo?.length ? (
                  <Accordion
                    items={[
                      {
                        heading: 'Product Details',
                        body: (
                          <Stack>
                            {accordionInfo.map((item, index) => (
                              <Stack key={index}>
                                <AccordionItem
                                  key={index}
                                  item={item}
                                  getOpenItem={() => index}
                                  updateOpenItem={() => index}
                                  hideArrow
                                  isChild
                                  isLastItem={index === accordionInfo.length - 1}
                                />
                              </Stack>
                            ))}
                          </Stack>
                        ),
                        index: 1,
                      },
                    ]}
                  />
                ) : null}
                <Divider bg="gallery" />
              </VStack>
              {/* *************** REVIEWS ********************* */}
              {reviews && reviews.length > 0 && (
                <VStack
                  justifyContent="center"
                  alignItems="center"
                  // my={{ base: 4, lg: 6 }}
                  space={5}
                >
                  <Heading size="bodyMlToTitleMd" textAlign="center" fontWeight="bold">
                    Reviews
                  </Heading>
                  <ReviewCarousel data={reviewsWithRecipe} width={{ base: 311, md: 400 }} />
                </VStack>
              )}
            </VStack>
          </VStack>
        </ScrollView>
        {showCTA ? (
          <HStack
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            w="100%"
            position="sticky"
            pt={6}
            pb={{ base: 4, lg: 6 }}
            bottom={0}
            h="fit-content"
            bg="white"
            style={{
              shadowColor: 'rgba(0,0,0,0.15)',
              shadowOffset: { width: -4, height: -4 },
              shadowRadius: 4,
            }}
          >
            <Button
              w={{ base: '311px', lg: '290px' }}
              borderWidth={{ base: '2px', lg: '3px' }}
              onPress={isV2Eligible ? onPressV2 : onPressV1}
              variant="primary"
              isLoading={isLoading}
            >
              {isSubscribeAndSaveSelected ? buttonText : buttonOneTimeText}
            </Button>
          </HStack>
        ) : null}
      </Modal.Content>
    </Modal>
  );
};

import { VStack } from 'native-base';

import { AvatarImageLarge } from '../Avatar';
import { ResponsiveCarousel } from './ResponsiveCarousel';

import { PetGender, PetStatus } from '@/api';

type PetInfo = {
  petId: string;
  petName: string;
  petGender?: PetGender;
  petImageUrl?: string;
  petStatus?: PetStatus;
};

type AvatarCarouselProps = {
  petData: PetInfo[];
  selectedId?: string;
  setSelectedId: (args: any) => void;
};

export const AvatarCarousel = ({ petData, selectedId, setSelectedId }: AvatarCarouselProps) => {
  const petIndex = petData.findIndex((petInfo) => petInfo.petId === selectedId);
  const transitionCarousel = (index: number) => {
    const newSelectedPet = petData[index];
    if (newSelectedPet) {
      setSelectedId(newSelectedPet.petId);
    }
  };
  return (
    <VStack pt={{ base: 6, lg: 12 }}>
      <ResponsiveCarousel
        controlledIndex={petIndex}
        data={petData}
        width={{ base: 256, md: 298 }}
        height={{ base: 256, md: 378 }}
        itemWidth={{ base: 256, md: 298 }}
        style={{ width: '840px' }}
        indicatorPlacement="none"
        onPrev={transitionCarousel}
        onNext={transitionCarousel}
        renderItem={({ item }: { item: PetInfo }) => (
          <AvatarImageLarge
            petId={item.petId}
            petGender={item.petGender}
            petStatus={item.petStatus}
            petImageUrl={item.petImageUrl}
          />
        )}
      />
    </VStack>
  );
};

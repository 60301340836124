import { Divider, HStack, IStackProps, Stack, Text } from 'native-base';

import { Pet, PetPlanProduct, RecipeType } from '@/api/types';
import { Avatar } from '@/components/Elements';
import { Addons } from '@/components/Portal/DogsAndPlans/PlanSummaryCard/Addons';
import { Meals } from '@/components/Portal/DogsAndPlans/PlanSummaryCard/Meals';
import { getPetPlanAddons } from '@/utils';

export interface ReactivatePlanLineItemsProps extends IStackProps {
  pet: Pet;
  orderDate?: Date;
  disabled?: boolean;
  onDecAddon?: (product: PetPlanProduct) => void;
  onIncAddon?: (product: PetPlanProduct) => void;
}

export const ReactivatePlanSummary = ({
  pet,
  disabled,
  onDecAddon,
  onIncAddon,
  orderDate,
  ...props
}: ReactivatePlanLineItemsProps) => {
  const snacks = getPetPlanAddons(pet.pet_plan, RecipeType.SNACK);
  const supplements = getPetPlanAddons(pet.pet_plan, RecipeType.SUPPLEMENT);

  return (
    <Stack w="100%" variant="card" space={4} {...props}>
      <Stack flex={1} alignItems="start" space={{ base: '8px', lg: '16px' }}>
        <HStack w="100%" justifyContent="space-between">
          <Avatar
            petId={pet.id}
            name={`${pet.name}'s Plan`}
            nameProps={{
              size: 'bodyMlToTitleSm',
              fontWeight: 'bold',
            }}
            imageUrl={pet.image_url}
            imageSize={{ base: '24px', lg: '36px' }}
            containerProps={{ direction: 'row', space: '8px' }}
          />
        </HStack>
        <Text size="bodySmToMd" color="sntGrey.primary">
          Delivered every {pet.pet_plan.frequency} weeks
        </Text>
      </Stack>
      <Divider bg="gallery" />
      <Meals
        pet={pet}
        petPlan={pet.pet_plan}
        successRedirect="ReactivatePlan"
        successRedirectParams={{ petId: pet.id, date: orderDate }}
      />
      {snacks.length > 0 ? (
        <>
          <Divider bg="gallery" />
          <Addons
            addonsType={RecipeType.SNACK}
            data={snacks}
            disabled={disabled}
            pet={pet}
            petPlan={pet.pet_plan}
          />
        </>
      ) : null}
      {supplements.length > 0 ? (
        <>
          <Divider bg="gallery" />
          <Addons
            addonsType={RecipeType.SUPPLEMENT}
            data={supplements}
            disabled={disabled}
            pet={pet}
            petPlan={pet.pet_plan}
          />
        </>
      ) : null}
    </Stack>
  );
};

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {
  Button,
  ChevronLeftIcon,
  CloseIcon,
  HStack,
  Heading,
  IModalProps,
  IconButton,
  Modal,
  VStack,
} from 'native-base';
import { useCallback, useEffect, useState } from 'react';

import { getSurveyOptions, SurveyQuestion } from './surveyOptions';

import { PetPlan, PetPlanProduct, SurveyResponse, SurveyTag, useCreateSurveyResponse } from '@/api';
import { SurveyRadioGroup } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { getActivePlans } from '@/utils';

export interface CancelAddonsModalProps extends IModalProps {
  product: PetPlanProduct;
  petPlan?: PetPlan;
  closeModal: () => void;
  onSubmit: () => void;
}

export const CancelAddonsModal = ({
  product,
  petPlan,
  isOpen,
  closeModal,
  onSubmit,
}: CancelAddonsModalProps) => {
  const account = useAccount();
  const productPetPlan =
    petPlan || getActivePlans(account).find((petplan) => petplan.id === product.pet_plan);
  const { mutateAsync: createSurveyResponse, isLoading: isLoadingSurveyResponse } =
    useCreateSurveyResponse();
  const [selectedOption, setSelectedOption] = useState<SurveyQuestion | undefined>();
  const [responseStep, setResponseStep] = useState<'primary' | 'secondary'>('primary');
  const [otherText, setOtherText] = useState('');
  const [surveyResponse, setSurveyResponse] = useState<SurveyResponse>({
    primary_answer: '',
    pet_plan_product: product.id,
    product_code: product.code,
    pet_plan: productPetPlan?.id,
    pet_plan_product_created_at: product.created,
    unit_price: product.unit_price,
    survey_tag: SurveyTag.ADDON_CANCEL,
  });

  const surveyOptions = getSurveyOptions(product?.recipes?.[0].type);

  const [displayedSurveyOptions, setDisplayedSurveyOptions] = useState<SurveyQuestion[]>();
  const recipeNames = product?.recipes.map((recipe) => recipe.name).join(', ');

  if (isOpen) {
    disableBodyScroll(document);
  } else {
    enableBodyScroll(document);
  }

  const resetToPrimarySurveyOptions = useCallback(() => {
    if (surveyOptions) {
      const primaryOptions = surveyOptions.map((option) => option.primary);
      setDisplayedSurveyOptions(primaryOptions);
      setResponseStep('primary');
    }
  }, [surveyOptions]);

  useEffect(() => {
    resetToPrimarySurveyOptions();
  }, [resetToPrimarySurveyOptions]);

  const clearSurveyResponse = () => {
    setSelectedOption(undefined);
    setOtherText('');
    setSurveyResponse({
      primary_answer: '',
      secondary_answer: '',
      customer_comments: '',
      pet_plan_product: undefined,
      product_code: undefined,
      pet_plan: undefined,
      pet_plan_product_created_at: undefined,
      unit_price: undefined,
      survey_tag: SurveyTag.ADDON_CANCEL,
    });
  };

  const submitResponseAndClose = (response: SurveyResponse) => {
    setSurveyResponse(response);
    createSurveyResponse({ accountId: account.id, data: response }).then(() => onSubmit());
    clearSurveyResponse();
    resetToPrimarySurveyOptions();
    closeModal();
  };

  const onContinue = () => {
    if (!selectedOption) return;

    const correspondingOption = surveyOptions?.find(
      (option) => option.primary.label === selectedOption.label
    );
    if (responseStep === 'primary' && correspondingOption?.secondary) {
      setSurveyResponse({
        ...surveyResponse,
        primary_answer: selectedOption.label,
      });
      setDisplayedSurveyOptions(correspondingOption.secondary);
      setResponseStep('secondary');
      setSelectedOption(undefined);
    } else if (responseStep === 'primary') {
      const updatedResponse = {
        ...surveyResponse,
        primary_answer: selectedOption.label,
        customer_comments: otherText,
      };
      submitResponseAndClose(updatedResponse);
    } else if (responseStep === 'secondary') {
      const updatedResponse = {
        ...surveyResponse,
        secondary_answer: selectedOption.label,
        customer_comments: otherText,
      };
      submitResponseAndClose(updatedResponse);
    }
  };

  const onPressBack = () => {
    setSurveyResponse({
      ...surveyResponse,
      secondary_answer: '',
      customer_comments: '',
    });
    setSelectedOption(
      surveyOptions?.find((option) => option.primary.label === surveyResponse.primary_answer)
        ?.primary
    );
    resetToPrimarySurveyOptions();
  };

  const onPressClose = () => {
    closeModal();
    resetToPrimarySurveyOptions();
    clearSurveyResponse();
  };

  return (
    <Modal
      isOpen={isOpen}
      useRNModal
      size="full"
      px={4}
      maxW="752px"
      marginLeft="auto"
      marginRight="auto"
    >
      <Modal.Header w="100%" bg="white">
        <HStack justifyContent="center" w="100%">
          {responseStep === 'secondary' && (
            <IconButton
              onPress={onPressBack}
              icon={<ChevronLeftIcon color="black" />}
              p={0}
              size={{ base: '18px', lg: '24px' }}
              position="absolute"
              top={0}
              left={0}
            />
          )}
          <Heading textAlign="center" size="bodyMlToTitleSm" fontWeight="bold" px={4}>
            {responseStep === 'primary'
              ? `Why are you removing ${recipeNames} from your plan?`
              : "Let's Get Specific"}
          </Heading>
          <IconButton
            onPress={onPressClose}
            icon={<CloseIcon color="black" />}
            p={0}
            size={{ base: '18px', lg: '24px' }}
            position="absolute"
            top={0}
            right={0}
          />
        </HStack>
      </Modal.Header>
      <VStack bg="white" w="100%" maxH="70vh" overflowY="scroll" p={{ base: 4, lg: 6 }}>
        {displayedSurveyOptions && (
          <SurveyRadioGroup
            surveyOptions={displayedSurveyOptions}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            otherText={otherText}
            setOtherTextControlled={setOtherText}
          />
        )}
      </VStack>
      <Modal.Footer w="100%" bg="white">
        <HStack justifyContent="center" w="100%">
          <Button
            variant="hugPrimary"
            px={{ base: 4, lg: 9 }}
            isDisabled={
              !selectedOption ||
              (selectedOption.commentRequired && !otherText) ||
              isLoadingSurveyResponse
            }
            onPress={onContinue}
          >
            CONTINUE
          </Button>
        </HStack>
      </Modal.Footer>
    </Modal>
  );
};

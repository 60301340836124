import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Heading, HStack, Text, VStack } from 'native-base';
import { useCallback, useState } from 'react';

import {
  Account,
  Order,
  OrderProduct,
  OrderStatus,
  PetPlanProduct,
  PetStatus,
  ProductType,
  useAddonRecipes,
  useAddProductToPetPlan,
} from '@/api';
import { Button, CloseIconButton, displayToast, Image, ToastType } from '@/components/Elements';
import { DogSelectModal } from '@/components/Portal/DogSelectModal';
import { ASSETS_URL } from '@/constants';
import { useAccount, useAuth } from '@/hooks';
import { ProtectedStackParamList } from '@/types';
import {
  capitalize,
  getOneTimeAddOnName,
  roundPriceShort,
  sortByProductTypeAndSeqNo,
} from '@/utils';

const { logger } = datadogLogs;

export interface OrderProductWithOrderInfo extends OrderProduct {
  orderId: Order['id'];
  delivered: Order['delivered'];
}

const getOneTimeAddOns = (orders: Order[]): OrderProductWithOrderInfo[] => {
  const orderProducts = orders.flatMap((order) =>
    order.products.map((product) => ({ ...product, orderId: order.id, delivered: order.delivered }))
  );
  return orderProducts.filter(
    (orderProduct) =>
      !orderProduct.recurring &&
      [ProductType.SNACK, ProductType.SUPPLEMENT].includes(orderProduct.product_type)
  );
};

const isRecurringEquivalent = (oneTimeProduct: OrderProduct, ppp: PetPlanProduct) => {
  const oneTimeRecipeIds = oneTimeProduct.recipes.map((recipe) => recipe.id);
  const pppRecipeIds = ppp.recipes.map((recipe) => recipe.id);
  return pppRecipeIds.every((pppRecipeId) => oneTimeRecipeIds.includes(pppRecipeId));
};

const hasRecurringEquivalent = (oneTimeProduct: OrderProduct, ppProducts?: PetPlanProduct[]) => {
  return ppProducts?.some((ppp) => isRecurringEquivalent(oneTimeProduct, ppp));
};

const getEligibleProduct = (account?: Account): OrderProductWithOrderInfo | undefined => {
  const deliveredOrders = account?.orders?.past?.filter(
    (order) => order.status === OrderStatus.COMPLETE && !!order.delivered
  );

  if (!deliveredOrders?.length) return;

  const oneTimeAddOns = getOneTimeAddOns(deliveredOrders);
  const allRecurringProducts = account?.petplans?.flatMap((petPlan) => petPlan.products);

  const eligibleOneTimeProducts: OrderProductWithOrderInfo[] = [];
  oneTimeAddOns.forEach((oneTimeProduct) => {
    if (!hasRecurringEquivalent(oneTimeProduct, allRecurringProducts)) {
      eligibleOneTimeProducts.push(oneTimeProduct);
    }
  });

  const previousQualifyingOrder = getPreviousQualifyingOrder();
  return eligibleOneTimeProducts.sort(sortByProductTypeAndSeqNo).filter((op) => {
    return (
      !previousQualifyingOrder?.orderId ||
      (op.orderId !== previousQualifyingOrder.orderId &&
        Date.parse(op.delivered) > Date.parse(previousQualifyingOrder.delivered))
    );
  })?.[0];
};

const getPreviousQualifyingOrder = (): { orderId: string; delivered: string } => {
  const previousQualifyingOrder = localStorage.getItem('previousQualifyingOrder');
  return previousQualifyingOrder ? JSON.parse(previousQualifyingOrder) : {};
};

export const UpsellCard = () => {
  const navigation = useNavigation<StackNavigationProp<ProtectedStackParamList>>();
  const account = useAccount();
  const { refetchUser } = useAuth();
  const activePets = account?.pets.filter((pet) => pet.status === PetStatus.ACTIVE);

  const { data: allAddOnRecipes } = useAddonRecipes(account?.id);
  const { mutateAsync: addAddon, isLoading: isAddProductToPetPlanLoading } =
    useAddProductToPetPlan();
  const [hideCard, setHideCard] = useState(true); // Set localStorage back to false when length of pastOrders changes????
  const [isDogSelectOpen, setIsDogSelectOpen] = useState(false);

  const pastOrderIds = account?.orders?.past?.map((order) => order.id).join();
  const petPlanProductCodes = account?.petplans
    ?.flatMap((petPlan) => petPlan.products.map((ppp) => ppp.code))
    .join();
  const oneTimeOrderProduct = getEligibleProduct(account);

  useFocusEffect(
    useCallback(() => {
      if (!oneTimeOrderProduct?.orderId) return;

      const previousQualifyingOrder = getPreviousQualifyingOrder();
      if (oneTimeOrderProduct.orderId !== previousQualifyingOrder.orderId) {
        localStorage.removeItem('closedUpsellCard');
      }
      if (!localStorage.getItem('closedUpsellCard')) {
        setHideCard(false);
      } else {
        setHideCard(true);
      }
    }, [pastOrderIds, petPlanProductCodes])
  );

  const setLocalStorage = () => {
    localStorage.setItem('closedUpsellCard', 'true');
    if (oneTimeOrderProduct?.orderId) {
      localStorage.setItem(
        'previousQualifyingOrder',
        JSON.stringify({
          orderId: oneTimeOrderProduct.orderId,
          delivered: oneTimeOrderProduct.delivered,
        })
      );
    }
  };

  const imageSource = `${ASSETS_URL}/pictos/${capitalize(oneTimeOrderProduct?.recipes?.[0].type)}/${
    oneTimeOrderProduct?.recipes?.[0].id
  }.png`;

  const recipeData = allAddOnRecipes?.find(
    (recipe) => recipe.id === oneTimeOrderProduct?.recipes[0].id
  );
  const recipeName = getOneTimeAddOnName(oneTimeOrderProduct);
  const recurringCode = recipeData?.default_product?.code;
  const recurringPrice = recipeData?.default_product?.price.price;
  const oneTimePrice = recipeData?.one_time_product?.price.price;
  const unit = oneTimeOrderProduct?.product_type === ProductType.SUPPLEMENT ? 'pack' : 'bag';

  const handleAdd = async () => {
    if (!oneTimeOrderProduct || !recurringCode) return;
    if (activePets?.length > 1) {
      setIsDogSelectOpen(true);
      return;
    }

    const pet = account?.pets.find((pet) => pet.id === oneTimeOrderProduct.pet_plan?.pet_id);
    if (pet) {
      try {
        await addAddon({
          petPlanId: pet.pet_plan.id,
          products: [{ code: recurringCode, quantity: 1, recurring: true }],
        });
        setLocalStorage();
        setHideCard(true);

        await refetchUser();
        displayToast({
          message: `${recipeName} have been added to ${pet.name}'s plan.`,
          type: ToastType.Success,
          onPress: () => navigation.push('Dogs', { petId: pet.id, scrollToTop: true }),
        });
      } catch (err) {
        displayToast({
          message: `An error occurred while adding ${recipeName} to ${pet.name}'s plan.`,
          type: ToastType.Error,
        });
        logger.error(`Error adding ${recipeName} to ${pet.name}'s plan: ${err}`);
      }
    }
  };

  return !hideCard && oneTimeOrderProduct && oneTimePrice && recurringPrice ? (
    <VStack w="100%" px={{ base: 4, lg: 0 }} alignItems="center">
      <VStack
        alignItems="center"
        bg="sntBlue.primary20"
        w="100%"
        maxW={{ md: '540px' }}
        p={{ base: 4, lg: 6 }}
        borderRadius={{ base: '8px', lg: '25px' }}
        space={{ base: 1, lg: 2 }}
      >
        <CloseIconButton
          onPress={() => {
            setLocalStorage();
            setHideCard(true);
          }}
          size={{ base: '18px', lg: '24px' }}
          iconProps={{ color: 'black' }}
          top={{ base: 4, lg: 6 }}
          right={{ base: 4, lg: 6 }}
        />
        <Heading size="bodyMdToLg" fontWeight="bold">
          {`Did your pup enjoy ${recipeName}?`}
        </Heading>
        <Text fontFamily="secondary" size="bodySmToMd" fontWeight="medium">
          Subscribe and save on every order!
        </Text>
        <HStack justifyContent="center" alignItems="center" w="100%" space={{ base: 2, lg: 5 }}>
          <Image size={{ base: '84px', lg: '128px' }} source={{ uri: imageSource }} />
          <VStack alignItems="center" space={{ base: 1, lg: 2 }}>
            <Text
              size="tinyToBodySm"
              color="sntSubscriptionPricing"
              fontWeight="medium"
            >{`One-Time Price $${roundPriceShort(oneTimePrice)}`}</Text>
            <Text size="bodySmToLg" fontWeight="bold">{`$${roundPriceShort(
              recurringPrice
            )}/${unit}`}</Text>
            <Button
              variant="underlineMini"
              size="bodyMdToLg"
              isDisabled={isAddProductToPetPlanLoading}
              onPress={handleAdd}
            >
              Subscribe & Save
            </Button>
          </VStack>
        </HStack>
      </VStack>
      {activePets?.length > 1 ? (
        <DogSelectModal
          isOpen={isDogSelectOpen}
          onClose={() => setIsDogSelectOpen(false)}
          onSuccess={() => {
            setLocalStorage();
            setIsDogSelectOpen(false);
          }}
          recipe={recipeData}
          petIds={activePets.map((pet) => pet.id)}
          onConfirm={addAddon}
          isLoading={isAddProductToPetPlanLoading}
          showQuantityByPet
        />
      ) : null}
    </VStack>
  ) : null;
};

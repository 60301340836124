import { Stack, Heading } from 'native-base';

import { FoodSection } from './FoodSection';

import { Order, Pet } from '@/api/types';
import { EditableCardProps } from '@/components/Elements';
import { addonSeqNoCompare, getProductCategoriesByOrder, sortByProductType } from '@/utils';

interface OrderSummaryProps extends EditableCardProps {
  order: Order;
  pets?: Pet[];
}

export const ProductsSummarySection = ({ order, pets = [] }: OrderSummaryProps) => {
  const productsCategories = getProductCategoriesByOrder(order, pets);

  const allOneTimeProducts = productsCategories.flatMap((planData) =>
    planData.oneTimeProducts.sort(sortByProductType)
  );

  return (
    <>
      {productsCategories.map((planData) => {
        const { snacks, meals, supplements, dailyEssentials, pet } = planData;
        const sortedSnacks = snacks.sort(addonSeqNoCompare);
        const sortedSupplements = supplements.sort(addonSeqNoCompare);
        const sortedDailyEssentials = dailyEssentials.sort(addonSeqNoCompare);
        const oneTimeProductsExist =
          meals.length || sortedSnacks.length || sortedSupplements.length;

        return oneTimeProductsExist ? (
          <Stack key={planData.pet.id}>
            <Heading size="bodyMdToLg" fontWeight="bold" pb={4} textAlign="center">
              {pet?.name}
            </Heading>
            <FoodSection products={meals} type="Meals" />
            <FoodSection products={sortedSnacks} type="Snacks" />
            <FoodSection products={sortedSupplements} type="Supplements" />
            <FoodSection products={sortedDailyEssentials} type="Daily Essentials" />
          </Stack>
        ) : null;
      })}

      {allOneTimeProducts.length > 0 && (
        <Stack>
          <Heading size="bodyMdToLg" fontWeight="bold" pb={4} textAlign="center">
            One-Time Products
          </Heading>
          <FoodSection products={allOneTimeProducts} type="One-Time Products" />
        </Stack>
      )}
    </>
  );
};

import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Stack, Heading, Text, Button, Badge, HStack } from 'native-base';

import { Pet } from '@/api/types';
import { Avatar } from '@/components/Elements';
import { Subscribe } from '@/components/Icons';
import { ProtectedStackParamList } from '@/types';

export interface LineItemPlanCardHeadingProps {
  dog: Pet;
  frequency?: number;
  planStatus?: string;
  orderEditable: boolean;
  disabled?: boolean;
}

export const LineItemPlanCardHeading = ({
  dog,
  frequency,
  orderEditable,
  disabled,
  planStatus,
}: LineItemPlanCardHeadingProps) => {
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  return (
    <Stack space={{ base: 2, lg: 4 }}>
      <HStack justifyContent="flex-start" alignItems="center">
        <Avatar
          petId={dog.id}
          petGender={dog.gender}
          imageUrl={dog.image_url}
          imageSize={{ base: 6, lg: 9 }}
        />
        <HStack flex={9} justifyContent="space-between">
          <Heading
            size="bodyMlToTitleSm"
            px={{ base: 2, lg: 4 }}
            fontWeight="bold"
            color={disabled ? 'sntGrey.primary' : undefined}
          >
            {dog?.name}'s Plan
          </Heading>

          {!disabled && orderEditable ? (
            <Button
              onPress={() => navigation.navigate('Dogs', { petId: dog.id })}
              variant="underlineMini"
              size="bodySmToMd"
            >
              Edit
            </Button>
          ) : null}
          {disabled ? <Badge variant="smallTabInactive">{planStatus}</Badge> : null}
        </HStack>
      </HStack>
      {frequency && orderEditable ? (
        <HStack alignItems="center">
          <Subscribe size={{ base: 3.5, lg: 5 }} mr="5px" fill="#828282" />
          <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
            Delivered every {frequency} weeks
          </Text>
        </HStack>
      ) : null}
    </Stack>
  );
};

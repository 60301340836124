import { useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { getInitialURL } from 'expo-linking';
import { Text } from 'native-base';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ActivityIndicator, Linking } from 'react-native';

import ErrorScreen from './components/Elements/ErrorScreen';
import { ToastType, displayToast } from './components/Elements/Toast';
import { FunnelScreens, FunnelScreenNames, HOW_TO_CONTACT_SUPPORT_URL } from './constants';
import {
  AxiosStatusProvider,
  CountdownTimerProvider,
  DogsLastVisitedScreenContext,
  dogsVisitedScreensType,
} from './context';
import { AxonProvider } from './context/AxonContext';
import { FunnelComponents } from './screens';
import { FunnelScreenProps, FunnelStackParamList, RootScreenNavigationProp } from './types';
import { sendFunnelErrorReport } from './utils';

import { usePetFormOptions } from '@/api';
import { useAuth, useIsMobile, useSessionQuery } from '@/hooks';

dayjs.extend(duration);

export function Questionnaire() {
  const { user } = useAuth();
  const { session, applyDiscounts } = useSessionQuery();
  const currentDogIndex = session?.current_pet_index as number;
  const navigation = useNavigation<RootScreenNavigationProp>();
  const Stack = createNativeStackNavigator<FunnelStackParamList>();
  const isMobile = useIsMobile();
  useEffect(() => {
    if (!isMobile) {
      window.Gladly?.init({ autoShowButton: true, appId: 'spotandtango.com' });
    }
  }, [isMobile]);

  const SKIPPED_SCREENS = [
    FunnelScreenNames.NAME_AND_EMAIL,
    FunnelScreenNames.HOW_MANY_DOGS,
    FunnelScreenNames.CALCULATING,
    FunnelScreenNames.CHECKOUT,
    FunnelScreenNames.POST_CHECKOUT,
    FunnelScreenNames.PROCESSING_ORDER,
    FunnelScreenNames.LOCATION,
    FunnelScreenNames.DOG_NAME,
    FunnelScreenNames.PORTAL,
    FunnelScreenNames.WELCOME_SCREEN,
  ];
  const PET_INFO_FUNNEL_STEPS = Object.values(FunnelScreenNames).filter(
    (screen) => !SKIPPED_SCREENS.includes(screen)
  );

  useEffect(() => {
    const updateDiscountCodes = async () => {
      const initialUrl = await getInitialURL();
      if (initialUrl) {
        const urlObject = new URL(initialUrl);
        const urlDiscountCodes = urlObject.searchParams.get('discount');
        if (urlDiscountCodes) {
          try {
            if (session?.id && urlDiscountCodes) {
              await applyDiscounts(urlDiscountCodes.split(','));
            }
          } catch (error) {
            displayToast({
              onPress: () => Linking.openURL(HOW_TO_CONTACT_SUPPORT_URL),
              // String message used in Toast accessibility message
              message:
                "The promo code you've entered is invalid. Please try again or contact us for help.",
              styledMessage: (
                <>
                  The promo code you've entered is invalid. Please try again or{' '}
                  <Text textDecorationLine="underline" fontWeight={900}>
                    contact us
                  </Text>{' '}
                  for help.
                </>
              ),
              type: ToastType.Error,
              duration: 9000,
            });
          }
        }
      }
    };
    updateDiscountCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dogsVisitedScreens, setDogsVisitedScreens] = useState<dogsVisitedScreensType>({
    0: FunnelScreenNames.GENDER,
  });

  const changeLastVisitedScreenForDog = (dogIndex: number, screen: FunnelScreenNames) => {
    setDogsVisitedScreens((prev: dogsVisitedScreensType) => ({ ...prev, [dogIndex]: screen }));
  };

  const initWithCountOfDogs = (countOfDogs: number) => {
    const objOfDogs: dogsVisitedScreensType = {};
    for (let i = 0; i < countOfDogs; i++) {
      objOfDogs[i] = i === 0 ? FunnelScreenNames.GENDER : null;
    }

    setDogsVisitedScreens(objOfDogs);
  };

  useEffect(() => {
    if (user && localStorage.getItem('auth_token')) {
      navigation.navigate(FunnelScreenNames.PORTAL);
    }
  }, [user]);

  usePetFormOptions();

  const FunnelStack = useMemo(() => {
    return Object.entries(FunnelComponents).map(([name, screen]) => {
      return (
        <Stack.Screen
          key={name}
          name={name as keyof FunnelStackParamList}
          component={screen as React.ComponentType<FunnelScreenProps<keyof FunnelStackParamList>>}
          options={{ title: 'Spot & Tango' }}
        />
      );
    });
  }, [FunnelScreens.length]);

  return (
    <AxonProvider>
      <Suspense fallback={<ActivityIndicator />}>
        <ErrorBoundary
          onError={(error, screen) => {
            sendFunnelErrorReport(`${error.message} ${screen.componentStack}`);
          }}
          fallback={<ErrorScreen type="Funnel" />}
        >
          <DogsLastVisitedScreenContext.Provider
            value={{
              dogsVisitedScreens,
              changeLastVisitedScreenForDog,
              setDogsVisitedScreens,
              initWithCountOfDogs,
            }}
          >
            <CountdownTimerProvider>
              <AxiosStatusProvider>
                <Stack.Navigator
                  initialRouteName="NameAndEmail"
                  screenListeners={({ route }) => ({
                    state: () => {
                      if (PET_INFO_FUNNEL_STEPS.includes(route.name as FunnelScreenNames)) {
                        changeLastVisitedScreenForDog(
                          currentDogIndex,
                          route.name as FunnelScreenNames
                        );
                      }
                    },
                  })}
                  screenOptions={{
                    headerShown: false,
                  }}
                >
                  {FunnelStack}
                </Stack.Navigator>
              </AxiosStatusProvider>
            </CountdownTimerProvider>
          </DogsLastVisitedScreenContext.Provider>
        </ErrorBoundary>
      </Suspense>
    </AxonProvider>
  );
}

import { Heading } from 'native-base';
import React, { useEffect } from 'react';

import { FreshMealPlan } from './FreshMealPlan';
import { UnKibbleMealPlan } from './UnKibbleMealPlan';

import { FunnelWrapper } from '@/components/layouts';
import { useAxonTrack } from '@/hooks/useAxonTrack';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { getCurrentPet } from '@/utils/getCurrentPet';

export default function MealPlan() {
  const { session, isLoading } = useSessionQuery();

  const dog = getCurrentPet(session);

  const track = useAxonTrack();

  useEffect(() => {
    track('page_view');
  }, []);

  return isLoading || !dog ? null : (
    <FunnelWrapper containerProps={{ px: 0, maxW: '100%' }}>
      {dog?.food_type === 'FRESH' ? (
        <FreshMealPlan dog={dog} />
      ) : dog?.food_type === 'UNKIBBLE' ? (
        <UnKibbleMealPlan dog={dog} />
      ) : (
        <Heading>We're having trouble loading your food choice...</Heading>
      )}
    </FunnelWrapper>
  );
}

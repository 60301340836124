import { HStack, Text } from 'native-base';

import { CONFIG_BANNER_COPY, CONFIG_BANNER_EXPIRY } from '@/config';
import { useIsMobile } from '@/hooks';

function isConfigBannerActive() {
  const expiryDate = new Date(CONFIG_BANNER_EXPIRY);
  const now = new Date();

  return expiryDate > now;
}

export const NameAndEmailBanner = () => {
  const isMobile = useIsMobile();
  const shouldShowBanner = CONFIG_BANNER_COPY && isConfigBannerActive() && isMobile;

  if (!shouldShowBanner) {
    return null;
  }
  return (
    <HStack w="100%" py={4} alignItems="center" justifyContent="center" bg="black">
      <Text size="bodySmToMd" fontWeight={{ base: 'bold', lg: 'medium' }} color="white">
        {CONFIG_BANNER_COPY}
      </Text>
    </HStack>
  );
};

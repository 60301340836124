import { datadogLogs } from '@datadog/browser-logs';
import { useNavigation } from '@react-navigation/native';
import axios from 'axios';
import { useQuery, useMutation } from 'react-query';

import {
  apply_discounts,
  create_session,
  finalize_checkout,
  get_or_create_anonymous_user,
  get_or_create_by_email,
  get_session,
  partial_update_pet_answers,
  prepare_checkout,
  get_promotions,
  remove_discount,
  reset_password,
  update_email,
  update_session,
  clear_session_data,
  validate_checkout,
} from '@/api';
import { queryClient } from '@/lib/react-query';
import { RootScreenNavigationProp, Dog, FunnelSession, ValidateCheckoutPayload } from '@/types';
import { isNetworkError, retryAsync } from '@/utils';

const { logger } = datadogLogs;

export const useSessionQuery = () => {
  const navigator = useNavigation<RootScreenNavigationProp>();

  const {
    data: session,
    isLoading,
    isError,
    isFetching,
    error,
    refetch,
    remove,
  } = useQuery<FunnelSession | undefined>('session', getSession, {
    staleTime: 1000 * 60 * 60 * 24 * 7,
  });

  const getOrCreateAnonymousUser = useMutation(get_or_create_anonymous_user);

  const createSessionHandler = async () => {
    const response = await create_session();
    queryClient.setQueryData('session', response.data);
    return response.data;
  };

  const createSession = useMutation(createSessionHandler);

  const getOrCreateSessionByEmail = useMutation(get_or_create_by_email, {
    onSuccess: (data) => {
      queryClient.setQueryData('session', data);
      queryClient.invalidateQueries('session');
    },
  });

  const clearSessionDataHandler = ({ first_name }: { first_name: string }) => {
    const session = queryClient.getQueryData<FunnelSession>('session');

    if (!session?.id) {
      logger.error('session or session.id is undefined', { session });
      throw new Error('session or session.id is undefined');
    }

    return clear_session_data(session.id, first_name);
  };

  const updateSessionHandler = ({
    data,
    sendToKlaviyo,
  }: {
    data: Partial<FunnelSession>;
    sendToKlaviyo?: boolean;
  }) => {
    const session = queryClient.getQueryData<FunnelSession>('session');

    if (!session?.id) {
      logger.error('session or session.id is undefined', { session });
      throw new Error('session or session.id is undefined');
    }

    return update_session(session?.id, data, sendToKlaviyo);
  };

  async function getSession() {
    const data = queryClient.getQueryData<FunnelSession>('session');

    if (!data) {
      return;
    } else if (!data.id) {
      logger.error('Session ID is missing');
      throw new Error('Session ID is missing');
    }
    try {
      const response = await get_session(data.id);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        logger.info('session not found -- reset funnel to start', { url: error.config?.url });
        queryClient.setQueryData('session', undefined);
        navigator.popToTop();
        return;
      }
      throw error;
    }
  }

  const handleSuccess = (data: any) => {
    queryClient.setQueryDefaults('session', { cacheTime: 604800000 }); // ms in 1 week
    queryClient.setQueryData('session', { ...data });
    queryClient.invalidateQueries('session');
  };

  const handleError = (error: any, endpointName: string) => {
    if (axios.isAxiosError(error) && error?.request.status === 409) {
      return navigator.navigate('Portal');
    } else if (axios.isAxiosError(error) && error?.response?.status === 404) {
      logger.info('session not found -- reset funnel to start', { url: error.config?.url });
      queryClient.setQueryData('session', undefined);
      return navigator.popToTop();
    } else {
      logger.error(`Sessions api error: ${endpointName}: `, { error });
    }
  };

  const clearSessionData = useMutation(clearSessionDataHandler, {
    onSuccess: ({ data }) => handleSuccess(data),
    onError: (error) => handleError(error, 'clearSessionData'),
  });

  const updateSession = useMutation(updateSessionHandler, {
    onSuccess: ({ data }) => handleSuccess(data),
    onError: (error) => handleError(error, 'updateSession'),
  });

  const updatePetAnswer = useMutation(partial_update_pet_answers, {
    mutationKey: ['PetAnswers'],
    onSuccess: ({ data }) => {
      queryClient.setQueryData('session', data);
      queryClient.invalidateQueries('session');
    },
    onError: (error) => {
      if (axios.isAxiosError(error) && error?.response?.status === 404) {
        logger.info('session not found -- reset funnel to start', { url: error.config?.url });
        queryClient.setQueryData('session', undefined);
        return navigator.popToTop();
      } else {
        logger.error('partial_udpate_pet_answers:: Axios error', { error });
      }
    },
  });

  const mutateUpdatePetAnswer = (data: Partial<Dog>) => {
    const { id, current_pet_index } = queryClient.getQueryData<FunnelSession>(
      'session'
    ) as FunnelSession;
    return retryAsync({
      asyncFunction: () =>
        updatePetAnswer.mutateAsync({
          id,
          pet_index: current_pet_index as number,
          pet_data: data,
        }),
      maxAttempts: 2,
      delay: 1000,
      shouldRetry: isNetworkError,
    });
  };

  const mutateUpdateSession = ({
    data,
    sendToKlaviyo,
  }: {
    data: Partial<FunnelSession>;
    sendToKlaviyo?: boolean;
  }) => {
    return retryAsync({
      asyncFunction: () => updateSession.mutateAsync({ data, sendToKlaviyo }),
      maxAttempts: 2,
      delay: 1000,
      shouldRetry: isNetworkError,
    });
  };

  const mutateClearSessionAnswers = () => {
    const prevSession = queryClient.getQueryData('session') as FunnelSession;
    return clearSessionData.mutateAsync({
      first_name: prevSession.first,
    });
  };

  const applyDiscounts = async (discounts: string[]) => {
    const { id } = queryClient.getQueryData('session') as FunnelSession;
    await apply_discounts(id, discounts);
    queryClient.invalidateQueries('session');
  };

  const removeDiscount = async (discount: string) => {
    const { id } = queryClient.getQueryData('session') as FunnelSession;
    await remove_discount(id, discount);
    queryClient.invalidateQueries('session');
  };

  const prepareCheckout = async (atPageLoad?: boolean) => {
    const { id } = queryClient.getQueryData('session') as FunnelSession;

    const data = await prepare_checkout(id, atPageLoad);

    queryClient.setQueryDefaults(['cart'], { cacheTime: 604800000 }); // ms in 1 week
    queryClient.setQueryData(['cart'], data);

    // refresh any new changes to the session
    const session = await getSession();
    queryClient.setQueryData('session', session);
  };

  const getPromotions = async () => {
    const data = await get_promotions();
    queryClient.setQueryDefaults(['cart'], { cacheTime: 604800000 }); // ms in 1 week
    queryClient.setQueryData(['cart'], data);

    // refresh any new changes to the session
    const session = await getSession();
    queryClient.setQueryData('session', session);
    return data;
  };

  const updateEmail = ({ email }: { email: string }) => {
    const { id } = queryClient.getQueryData('session') as FunnelSession;
    return update_email(id, { new_email: email });
  };

  const finalize = async (params: { paymentMethodId: string }) => {
    const { id } = queryClient.getQueryData('session') as FunnelSession;
    const data = await finalize_checkout(id, params);
    localStorage.setItem('reset_token_id', data.reset_token);
    localStorage.setItem('reset_token_key', data.reset_key);

    return data;
  };

  const updatePassword = async (data: { password: string; password_confirm: string }) => {
    const resetTokenId = localStorage.getItem('reset_token_id');
    const resetTokenKey = localStorage.getItem('reset_token_key');
    if (!(resetTokenId && resetTokenKey)) {
      logger.error('Reset tokens not set for updating password');
      return;
    }
    const { auth_token } = await reset_password({
      ...data,
      reset_token_id: resetTokenId,
      reset_token_key: resetTokenKey,
    });
    const oldData = queryClient.getQueryData('session') as FunnelSession;
    return queryClient.setQueryData(['session'], {
      ...oldData,
      auth_token,
    });
  };

  const getSessionById = async (id: string) => {
    const response = await get_session(id);
    return response.data;
  };

  const clearSession = async () => {
    queryClient.clear();
    localStorage.removeItem('reset_token_id');
    localStorage.removeItem('reset_token_key');
  };

  const validateCheckout = (data: ValidateCheckoutPayload): Promise<void> => {
    const { id } = queryClient.getQueryData('session') as FunnelSession;
    return validate_checkout(id, data);
  };

  return {
    prepareCheckout,
    getPromotions,
    session,
    isLoading,
    updateEmail,
    finalize,
    updatePassword,
    isError,
    isFetching,
    error,
    refetch,
    remove,
    updateSession,
    createSession,
    getOrCreateSessionByEmail,
    applyDiscounts,
    removeDiscount,
    getSessionById,
    getSession,
    mutateUpdateSession,
    mutateClearSessionAnswers,
    mutateUpdatePetAnswer,
    clearSession,
    validateCheckout,
    getOrCreateAnonymousUser,
  };
};

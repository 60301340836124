import { useFocusEffect } from '@react-navigation/native';
import { FormControl, Stack, Heading, Input, View, Text, VStack } from 'native-base';
import { useCallback, useEffect } from 'react';
import { Controller, useForm, useFieldArray, SubmitHandler } from 'react-hook-form';

import { Button } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useIsMobile } from '@/hooks';
import { useAxonTrack } from '@/hooks/useAxonTrack';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FunnelScreenProps } from '@/types';

interface DogNameFormData {
  dogNames: {
    name: string;
  }[];
}

export default function DogName({ navigation }: FunnelScreenProps<'DogName'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.DOG_NAME);
  const { session, mutateUpdateSession } = useSessionQuery();
  const isMobile = useIsMobile();

  const numberOfDogs = session?.number_of_pets;
  const dogs = session?.pets;
  const defaultValues = {
    dogNames: [],
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setFocus,
    getValues,
  } = useForm<DogNameFormData>({ defaultValues, mode: 'onChange' });
  const funnelErrorHandler = useFunnelErrorHandler();
  const track = useAxonTrack();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dogNames',
  });

  useEffect(() => {
    track('page_view');
  }, []);

  useFocusEffect(
    useCallback(() => {
      // populate field array from session
      if (session?.pets) {
        remove();
        session?.pets?.forEach((dog) => {
          append({ name: dog.name || '' });
        });
      }
    }, [append, remove, session?.pets])
  );

  useEffect(() => {
    // once fields have loaded, focus the first field
    if (fields?.length > 0 && !isMobile) {
      setFocus('dogNames.0.name');
    }
  }, [fields, isMobile, setFocus]);

  const dogNames = watch('dogNames');
  const doesEveryControlledFieldHaveADogName: boolean = dogNames?.every((field) => {
    return field.name !== undefined && field.name !== '' && !errors?.dogNames;
  });

  if (!session || !numberOfDogs || !dogs || fields?.length === 0) {
    return null;
  }

  const onSubmit: SubmitHandler<DogNameFormData> = async ({ dogNames }) => {
    try {
      await mutateUpdateSession({
        data: {
          pets: dogNames.map(({ name: dogName }, i) => ({
            ...session.pets?.[i],
            name: dogName,
          })),
        },
        sendToKlaviyo: true,
      });
      navigation.navigate(nextStep);
    } catch (error) {
      funnelErrorHandler(error, 'Update dog name');
    }
  };

  return (
    <FunnelWrapper containerProps={{ space: '64px' }}>
      <VStack
        w="100%"
        space="48px"
        justifyContent={{ base: 'center', md: 'flex-start' }}
        alignItems="center"
        alignContent="center"
      >
        <Heading size="titleSmToMd" fontWeight="bold">
          What {dogs.length > 1 ? "are your dogs' names?" : "is your dog's name?"}
        </Heading>
        {fields && (
          <Stack
            justifyContent="center"
            alignItems="center"
            w={{ base: '100%', md: '472px' }}
            space="24px"
          >
            {fields.map((_field, index) => {
              return (
                <FormControl key={_field.id} isInvalid={!!errors?.dogNames?.[index]?.name}>
                  <VStack alignItems="center">
                    <Controller
                      control={control}
                      name={`dogNames.${index}.name`}
                      rules={{
                        maxLength: {
                          value: 12,
                          message: 'Name cannot exceed 12 characters',
                        },
                        required: {
                          value: true,
                          message: "Please enter your dog's name",
                        },
                        pattern: {
                          value: /^[A-Za-zÀ-ÖØ-öø-ÿ.' -]*$/,
                          message: 'Please enter a valid name',
                        },
                        validate: {
                          unique: (v) =>
                            getValues().dogNames.filter(
                              (el) =>
                                el.name.toLocaleLowerCase().trim() === v.toLocaleLowerCase().trim()
                            ).length >= 2
                              ? 'Please enter unique names for each of your pets'
                              : true,
                        },
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          variant={errors?.dogNames?.[index]?.name ? 'error' : 'base'}
                          ref={ref}
                          w="100%"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          placeholder="Dog's name"
                          onKeyPress={(event) => {
                            if (event.nativeEvent.key === 'Enter') {
                              event.preventDefault();
                              if (
                                index === fields.length - 1 &&
                                doesEveryControlledFieldHaveADogName
                              ) {
                                handleSubmit(onSubmit)();
                              } else {
                                setFocus(`dogNames.${index + 1}.name`);
                              }
                            }
                          }}
                          testID={`dog-name-input-${index}`}
                        />
                      )}
                    />
                    {errors?.dogNames?.[index] ? (
                      <View w="100%">
                        <Text size="bodySmToMd" mt="4px" color="error.default">
                          {errors?.dogNames?.[index]?.name?.message}
                        </Text>
                      </View>
                    ) : null}
                    {index < numberOfDogs - 1 && (
                      <View key={`anddognames-${_field.id}`} alignSelf="center" mt="24px">
                        <Text size={{ base: 'bodyMd', lg: 'bodyLg' }} fontWeight="bold">
                          and
                        </Text>
                      </View>
                    )}
                  </VStack>
                </FormControl>
              );
            })}
          </Stack>
        )}
      </VStack>
      <Button
        variant="primary"
        size="bodyMdToLg"
        onPress={handleSubmit(onSubmit)}
        isDisabled={!doesEveryControlledFieldHaveADogName}
        isLoading={isSubmitting}
        testID="dog-name-submit"
        w="100%"
        maxW={{ base: '100%', md: '290px' }}
        h={{ base: '52px', md: '56px' }}
      >
        CONTINUE
      </Button>
      {!isMobile && <View h="30px" w="100%" />}
    </FunnelWrapper>
  );
}

import { DAILY_ESSENTIAL_SURVEY_OPTIONS } from './cancelDailyEssentialSurveyOptions';
import { SNACK_SURVEY_OPTIONS } from './cancelSnackSurveyOptions';
import { SUPPLEMENT_SURVEY_OPTIONS } from './cancelSupplementSurveyOptions';

import { RecipeType } from '@/api';

export type SurveyQuestion = {
  label: string;
  showCommentField?: boolean;
  commentRequired?: boolean;
  isOtherOption?: boolean;
};

export interface SurveyOption {
  primary: SurveyQuestion;
  secondary?: SurveyQuestion[];
}

export const getSurveyOptions = (recipeType?: RecipeType) => {
  switch (recipeType) {
    case RecipeType.SNACK:
      return SNACK_SURVEY_OPTIONS;
    case RecipeType.SUPPLEMENT:
      return SUPPLEMENT_SURVEY_OPTIONS;
    case RecipeType.DC:
      return DAILY_ESSENTIAL_SURVEY_OPTIONS;
  }
};

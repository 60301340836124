import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Mask, Path, TMaskUnits, MaskProps } from 'react-native-svg';

import { MyG } from './Account';

import { SVGNBIconProps } from '@/types';

export interface MyMaskProps extends MaskProps {
  children: React.ReactNode;
  style: any;
}

export const MyMask = ({ ...props }: MyMaskProps) => {
  return <Mask {...props} />;
};

const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 18 18"
    {...props}
  >
    <MyMask
      id="photo-upload_svg__a"
      width={18}
      height={18}
      x={0}
      y={0}
      maskUnits={'userSpaceOnUse' as TMaskUnits}
      style={{
        maskType: 'alpha',
      }}
    >
      <Path fill="#D9D9D9" d="M0 0h18v18H0z" />
    </MyMask>
    <MyG mask="url(#photo-upload_svg__a)">
      <Path
        fill="#1C1B1F"
        d="M3.75 15.75c-.413 0-.766-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06V3.75c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44h10.5c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v10.5c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44H3.75Zm0-1.5h10.5V3.75H3.75v10.5Zm.75-1.5h9L10.687 9l-2.25 3L6.75 9.75l-2.25 3Z"
      />
    </MyG>
  </Svg>
);
function SvgPhotoUpload({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgPhotoUpload;

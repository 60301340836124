import { HStack, IStackProps, Stack, VStack } from 'native-base';
import { useState } from 'react';

import { ShopAnchorLink, ShopAnchorLinkSkeleton } from './ShopAnchorLink';
import { SectionContent } from './ShopTab';

import { useIsMobile } from '@/hooks';

interface StickyWrapperProps extends IStackProps {
  isMobile: boolean;
}

const StickyWrapper = ({ isMobile, ...props }: StickyWrapperProps) => {
  return isMobile ? (
    <HStack
      justifyContent="center"
      alignItems="center"
      space={4}
      position="sticky"
      top={0}
      left={0}
      {...props}
    />
  ) : (
    <VStack
      justifyContent="center"
      alignItems="start"
      space={9}
      py="72px"
      position="sticky"
      top={0}
      left={0}
      {...props}
    />
  );
};

interface ShopAnchorBarProps extends IStackProps {
  sectionList: SectionContent[];
  isLoading?: boolean;
}

export const ShopAnchorBar = ({ sectionList, isLoading = false, ...props }: ShopAnchorBarProps) => {
  const isMobile = useIsMobile();
  const [selectedLink, setSelectedLink] = useState<SectionContent['title']>('Snacks');

  const dropShadow = isMobile
    ? {
        bg: 'white',
        style: {
          shadowColor: 'rgba(0,0,0,0.15)',
          shadowOffset: { width: -4, height: 4 },
          shadowRadius: 4,
        },
      }
    : {};

  return (
    <Stack
      w={{ base: '100%', lg: 'fit-content' }}
      h={{ base: 'fit-content', lg: '100%' }}
      pr={{ base: 0, lg: 12 }}
      pl={{ base: 0, lg: 4 }}
      {...props}
    >
      <StickyWrapper isMobile={isMobile} {...dropShadow}>
        {sectionList.map(({ items, title, sectionHeaderRef, itemsRef, iconUri }, index) => {
          if (isLoading) {
            return <ShopAnchorLinkSkeleton key={index} isMobile={isMobile} />;
          }
          return items?.length && sectionHeaderRef && itemsRef ? (
            <ShopAnchorLink
              key={index}
              title={title}
              sectionHeaderRef={sectionHeaderRef}
              itemsRef={itemsRef}
              iconUri={iconUri}
              selectedLink={selectedLink}
              setSelectedLink={setSelectedLink}
            />
          ) : null;
        })}
      </StickyWrapper>
    </Stack>
  );
};

import { useIsFocused } from '@react-navigation/native';
import { AxiosError } from 'axios';
import {
  Heading,
  useBreakpointValue,
  Stack,
  Text,
  Pressable,
  Flex,
  IPressableProps,
} from 'native-base';
import { useEffect, useRef, useState } from 'react';

import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useDogsLastVisitedScreenContext } from '@/context';
import { updateDatadogFunnelUser } from '@/datadog';
import { useAxonTrack } from '@/hooks/useAxonTrack';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FunnelScreenProps } from '@/types';
import { PartialDog } from '@/types/FunnelSession';

export default function HowManyDogs({ navigation }: FunnelScreenProps<'HowManyDogs'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.HOW_MANY_DOGS);
  // Pulls in the session and the function to update the session data
  const { session, mutateUpdateSession } = useSessionQuery();
  // number of dogs selected by the user - used to set the item as selected if the user returns to this screen
  const [previousSelection, setPreviousSelection] = useState<number>(0);
  // current selection is the number of dogs selected by the user, used to prevent duplicate clicks on the button
  const [currentSelection, setCurrentSelection] = useState<number>();
  const isFocused = useIsFocused();
  const funnelErrorHandler = useFunnelErrorHandler();
  const isSubmittingRef = useRef(false);
  const track = useAxonTrack();

  const { initWithCountOfDogs } = useDogsLastVisitedScreenContext();

  const createDogsArray = (numberOfDogs: number) => {
    const dogs = [];
    for (let i = 0; i < numberOfDogs; i++) {
      dogs.push({
        name: '',
      });
    }
    return dogs;
  };

  useEffect(() => {
    const numberOfDogs = session?.number_of_pets;

    if (numberOfDogs) {
      setPreviousSelection(numberOfDogs);
    }
    setCurrentSelection(undefined);
    isSubmittingRef.current = false;
  }, [session, isFocused]);

  const primaryHeadingSize = useBreakpointValue({
    base: 'bodyMl',
    md: 'titleMd',
  });

  const onSubmit = async (number_of_pets: number) => {
    if (isSubmittingRef.current) return;
    isSubmittingRef.current = true;
    setCurrentSelection(number_of_pets);

    try {
      const dogs: PartialDog[] = createDogsArray(number_of_pets);
      await mutateUpdateSession({
        data: {
          number_of_pets,
          pets: dogs,
          current_pet_index: 0,
        },
      });
    } catch (error) {
      funnelErrorHandler(error as AxiosError, 'Update number of dogs');
      isSubmittingRef.current = false;
      setCurrentSelection(undefined);
      return;
    }

    updateDatadogFunnelUser('number_of_dogs', number_of_pets);

    initWithCountOfDogs(number_of_pets);
    navigation.navigate(nextStep);
  };

  useEffect(() => {
    track('page_view');
  }, []);

  return (
    <FunnelWrapper>
      <Stack pt={{ base: '200px', md: 0 }}>
        <Heading size={primaryHeadingSize} textAlign="center" fontWeight="bold">
          How many dogs do you have?
        </Heading>

        <Flex
          flexDirection="row"
          justifyContent="center"
          w="fit-content"
          mt={{ base: '30px', md: '42px' }}
        >
          {[1, 2, 3, 4].map((number, index) => (
            <DogAmountBox
              key={index}
              onPress={() => onSubmit(number)}
              isDisabled={isSubmittingRef.current}
              mx={{ base: '2.5px', md: '6px' }}
              isPressed={previousSelection === number}
              isSelected={currentSelection === number}
              testID="how-many-dogs-select"
            >
              {number}
            </DogAmountBox>
          ))}
        </Flex>
      </Stack>
    </FunnelWrapper>
  );
}

type DogAmountBoxProps = {
  // the cards inner content will be typeof number
  children: number;
  // margin left + margin right
  // mx: string | {{base: string, md: string }};
  // displays the selected styling if true
  isPressed: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
} & IPressableProps;

const DogAmountBox = (props: DogAmountBoxProps) => (
  <Pressable
    borderWidth="2px"
    w={{ base: '82px', md: '174px' }}
    h={{ base: '52px', md: '60px' }}
    style={{ borderColor: 'black' }}
    bg={props.isSelected ? 'sntBlue.disabled' : props.isDisabled ? 'sntGrey.outline' : undefined}
    _hover={props.isDisabled ? undefined : { bg: 'sntBlue.primary' }}
    _pressed={props.isDisabled ? undefined : { bg: 'sntBlue.primary' }}
    isDisabled={props.isDisabled}
    py="10px"
    justifyContent="center"
    {...props}
  >
    <Text
      my={0}
      fontWeight="bold"
      fontFamily="MonumentGrotesk-Regular"
      alignSelf="center"
      size="bodyMlToTitleSm"
      marginBottom={0}
    >
      {props.children}
    </Text>
  </Pressable>
);

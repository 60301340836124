import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Stack } from 'native-base';

import { BillingCard } from '../BillingCard';
import { OrderSummary } from '../OrderSummary';
import { ShippingAddress } from '../ShippingAddress';

import { usePaymentMethods } from '@/api';
import { ProductType } from '@/api/types';
import {
  Order,
  OrderProduct,
  OrderProductType,
  OrderStatus,
  OrderType,
  Pet,
} from '@/api/types/accountServices';
import {
  LineItemOneTimeCard,
  LineItemPlanCard,
  LineItemPlanCardProps,
  PromoCard,
} from '@/components/Elements';
import { NON_CUSTOMER_FACING_PRODUCT_CODES } from '@/constants';
import { useAccount } from '@/hooks';
import { ProtectedStackParamList } from '@/types';
import { dateFormat, isInPast, getBillingInfo, getShippingInfo } from '@/utils';

const isProductPlanEligible = (product: OrderProduct) => {
  return product.recurring || product.product_type === ProductType.MEAL;
};

export const OrderLineItems = ({ order }: { order: Order }) => {
  const account = useAccount();

  const { petplans, pets, addresses } = account;
  const navigation = useNavigation<NativeStackNavigationProp<ProtectedStackParamList>>();

  const primaryAddress = addresses.find((addr) => addr.is_primary) || addresses[0];

  const { data: paymentMethods } = usePaymentMethods();
  const stripeCard = paymentMethods?.find((method) => method.is_default)?.card;

  // take all the products in an order and group them by dog
  const dogPlans = order.products.reduce<LineItemPlanCardProps['content'][]>(
    (plans, orderProduct) => {
      const orderProductPetPlan = petplans.find(({ id }) => id === orderProduct.pet_plan?.id);

      if (!orderProductPetPlan) {
        return plans;
      }

      const petId = orderProductPetPlan.pet;
      let dogPlan = plans.find(({ dog: { id } }) => id === petId);
      if (!dogPlan) {
        dogPlan = {
          dog: pets.find(({ id }) => id === petId) as Pet,
          orderId: order.id,
          orderDate: order.created && dateFormat(new Date(order.created), 'long'),
          orderEditable: order.status === OrderStatus.PREVIEW,
          orderWasCharged: !!order.charged,
          frequency: order.order_type === OrderType.TRIAL ? 2 : orderProductPetPlan?.frequency,
          meals: [],
          snacks: [],
          supplements: [],
          dailyEssentials: [],
          extras: [],
          addons: [],
        };
        plans.push(dogPlan);
      }

      if (orderProduct.type === OrderProductType.ADDON) {
        dogPlan.addons.push(orderProduct);
      } else if (orderProduct.product_type === ProductType.SNACK) {
        dogPlan.snacks.push(orderProduct);
      } else if (
        orderProduct.product_type === ProductType.MEAL ||
        orderProduct.product_type === ProductType.SAMPLE ||
        orderProduct.product_type === ProductType.REPLACE
      ) {
        dogPlan.meals.push(orderProduct);
      } else if (orderProduct.product_type === ProductType.SUPPLEMENT) {
        dogPlan.supplements.push(orderProduct);
      } else if (orderProduct.product_type === ProductType.DC) {
        dogPlan.dailyEssentials.push(orderProduct);
      } else if (
        [ProductType.MATERIAL, ProductType.SCOOP, ProductType.MERCH].includes(
          orderProduct.product_type
        ) &&
        !NON_CUSTOMER_FACING_PRODUCT_CODES.includes(orderProduct.code)
      ) {
        dogPlan.extras.push(orderProduct);
      }
      return plans;
    },
    []
  );

  const isEditableCard = order.status === OrderStatus.HOLD || order.status === OrderStatus.PREVIEW;

  const cardFromOrder = getBillingInfo(order);
  const addressFromOrder = getShippingInfo(order);

  const dogPlansGroupedByRecurrence = dogPlans.reduce<{
    dogPlansWithRecurringProducts: LineItemPlanCardProps['content'][];
    dogPlansWithOneTimeProducts: LineItemPlanCardProps['content'][];
  }>(
    (acc, dogPlan) => {
      const dogPlansWithRecurringProducts = {
        ...dogPlan,
        meals: dogPlan.meals.filter((product) => isProductPlanEligible(product)),
        snacks: dogPlan.snacks.filter((product) => isProductPlanEligible(product)),
        supplements: dogPlan.supplements.filter((product) => isProductPlanEligible(product)),
        dailyEssentials: dogPlan.dailyEssentials.filter((product) =>
          isProductPlanEligible(product)
        ),
        extras: dogPlan.extras.filter((product) => isProductPlanEligible(product)),
        addons: dogPlan.addons.filter((product) => isProductPlanEligible(product)),
      };

      const dogPlansWithOneTimeProducts = {
        ...dogPlan,
        meals: dogPlan.meals.filter((product) => !isProductPlanEligible(product)),
        snacks: dogPlan.snacks.filter((product) => !isProductPlanEligible(product)),
        supplements: dogPlan.supplements.filter((product) => !isProductPlanEligible(product)),
        dailyEssentials: dogPlan.dailyEssentials.filter(
          (product) => !isProductPlanEligible(product)
        ),
        extras: dogPlan.extras.filter((product) => !isProductPlanEligible(product)),
        addons: dogPlan.addons.filter((product) => !isProductPlanEligible(product)),
      };

      const containsOneTime =
        dogPlansWithOneTimeProducts.meals.length ||
        dogPlansWithOneTimeProducts.snacks.length ||
        dogPlansWithOneTimeProducts.supplements.length ||
        dogPlansWithOneTimeProducts.dailyEssentials.length ||
        dogPlansWithOneTimeProducts.extras.length ||
        dogPlansWithOneTimeProducts.addons.length;

      const containRecurring =
        dogPlansWithRecurringProducts.meals.length ||
        dogPlansWithRecurringProducts.snacks.length ||
        dogPlansWithRecurringProducts.supplements.length ||
        dogPlansWithRecurringProducts.dailyEssentials.length ||
        dogPlansWithRecurringProducts.extras.length ||
        dogPlansWithRecurringProducts.addons.length;

      containRecurring && acc.dogPlansWithRecurringProducts.push(dogPlansWithRecurringProducts);
      containsOneTime && acc.dogPlansWithOneTimeProducts.push(dogPlansWithOneTimeProducts);
      return acc;
    },
    { dogPlansWithRecurringProducts: [], dogPlansWithOneTimeProducts: [] }
  );

  const { dogPlansWithRecurringProducts, dogPlansWithOneTimeProducts } =
    dogPlansGroupedByRecurrence;

  return (
    <Stack
      w="100%"
      justifyContent="center"
      alignItems="center"
      space={{ base: '16px', lg: '24px' }}
    >
      {dogPlansWithRecurringProducts.length
        ? dogPlansWithRecurringProducts
            .sort((planA, planB) => planA.dog?.name.localeCompare(planB.dog?.name))
            .map((dogPlan, i) => <LineItemPlanCard key={i} content={dogPlan} />)
        : null}
      {dogPlansWithOneTimeProducts.length ? (
        <LineItemOneTimeCard dogPlans={dogPlansWithOneTimeProducts} />
      ) : null}
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        space={{ base: '16px', lg: '24px' }}
        justifyContent="center"
        alignItems="center"
        w={{ base: '100%', lg: '408px' }}
      >
        <ShippingAddress
          address={!isEditableCard ? addressFromOrder : primaryAddress}
          disabled={!isEditableCard}
          chevron={isEditableCard ? 'right' : false}
        />
        <BillingCard
          disabled={!isEditableCard}
          chevron={isEditableCard ? 'right' : false}
          card={
            !isEditableCard ? cardFromOrder : { brand: stripeCard?.brand, last4: stripeCard?.last4 }
          }
          isFree={Number(order.total_price) === 0}
          onPress={() => navigation.navigate('PaymentMethodList', { successRedirect: 'Orders' })}
        />
      </Stack>
      {/* <FeedingInfo order={order} /> */}
      {order.charged && isInPast(new Date(order.charged)) && order.discounts.length === 0 ? null : (
        <PromoCard order={order} />
      )}
      <OrderSummary order={order} hasDropdown />
    </Stack>
  );
};

import { SurveyOption } from './surveyOptions';

export const DAILY_ESSENTIAL_SURVEY_OPTIONS: SurveyOption[] = [
  {
    primary: {
      label: `It's too expensive`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `I can't afford it within my budget`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `It didn't meet my expectations for the price`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `I can get the same product for lower cost from a different source`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      { label: `Other`, showCommentField: true, commentRequired: true, isOtherOption: true },
    ],
  },
  {
    primary: {
      label: `I'm unhappy with the quality of the bags`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `It tears too easily`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `It is too transparent`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `It is too small`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `It looks different than advertised`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `The quality varies order to order`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      { label: `Other`, showCommentField: true, commentRequired: true, isOtherOption: true },
    ],
  },
  {
    primary: {
      label: `I would rather purchase on a one-off basis`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `I dislike subscriptions in general, so I'll re-purchase when I'm ready`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `I have too much product between orders`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `I am running out of product between orders`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      { label: `Other`, showCommentField: true, commentRequired: true, isOtherOption: true },
    ],
  },
  {
    primary: {
      label: `I have a different issue not listed above`,
      showCommentField: true,
      commentRequired: true,
      isOtherOption: true,
    },
  },
];

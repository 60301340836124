import { datadogLogs } from '@datadog/browser-logs';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/lib/typescript/src/types';
import { Box, HStack, Stack, Text } from 'native-base';
import { useMemo, useState } from 'react';

import { DogSelectModal } from '../DogSelectModal';
import { SectionContent } from './ShopTab';

import { RecipeFull, RecipeType, useAddProductToPetPlan, useUpdateOrderProducts } from '@/api';
import {
  displayToast,
  ProductListingItem,
  ShopPDPModal,
  ToastType,
  VToHStack,
} from '@/components/Elements';
import SvgIllustratedStar from '@/components/Icons/IllustratedStar';
import { IS_CHEESEBITES_LAUNCHED } from '@/config';
import { ADDONS_CONFIGURATION, AddonsType, ASSETS_URL } from '@/constants';
import { useAccount, useActivePets, useAuth, useIsMobile } from '@/hooks';
import segment from '@/segment';
import { ProtectedStackParamList } from '@/types';
import {
  capitalize,
  getAddOnQuantitiesByPet,
  getFirstPetWithAddon,
  getNextOrderIdWithProduct,
  generatePriceObject,
  getUpcomingOrdersAddOnQuantitiesForPet,
} from '@/utils';

const { logger } = datadogLogs;

const StarBadge = ({ badgeContent }: { badgeContent: string }) => {
  return (
    <HStack alignItems="center" space={1}>
      <SvgIllustratedStar w={{ base: 3, lg: 4 }} h={{ base: 3, lg: 4 }} />
      <Text size="bodySmToMd" fontWeight="bold">
        {badgeContent}
      </Text>
    </HStack>
  );
};

type AddOnItemsProps = {
  recipes: RecipeFull[];
  itemsRef: SectionContent['itemsRef'];
  bannerRef?: { recipeId: RecipeFull['id']; ref: React.RefObject<HTMLDivElement> };
};

export const AddOnItems = ({ recipes, itemsRef, bannerRef }: AddOnItemsProps) => {
  const navigation = useNavigation<StackNavigationProp<ProtectedStackParamList>>();
  const isMobile = useIsMobile();
  const account = useAccount();
  const activePets = useActivePets();
  const petIdsForQuantityTotal = activePets?.map((pet) => pet.id);
  const { refetchUser } = useAuth();
  const { mutateAsync: addAddon } = useAddProductToPetPlan();
  const { mutateAsync: updateOrderProducts } = useUpdateOrderProducts();
  const [isUpdatingAddons, setIsUpdatingAddons] = useState(false);

  const recipeQuantities = useMemo(() => {
    if (!account || !recipes?.length || !petIdsForQuantityTotal) {
      return;
    }
    return getAddOnQuantitiesByPet(recipes, account, petIdsForQuantityTotal);
  }, [account, petIdsForQuantityTotal]);

  const displayRecipeQuantities = useMemo(() => {
    if (!account) return;
    return getUpcomingOrdersAddOnQuantitiesForPet(account);
  }, [account]);

  const [selectedRecipe, setSelectedRecipe] = useState<RecipeFull | null>(null);
  const [isPDPOpen, setIsPDPOpen] = useState(false);
  const [isDogSelectOpen, setIsDogSelectOpen] = useState(false);

  const onPressAddOn = (recipe: RecipeFull) => {
    segment.trackEvent('Click Add-On Product Details', {
      email: account?.email,
      product_code: recipe.default_product.code,
      pet_name: null, // kept for parity with old Segment events
      account_id: account?.id,
      session_id: null,
    });
    setSelectedRecipe(recipe);
    setIsPDPOpen(true);
  };

  const handleAdd = async (
    code: string | undefined,
    petId: string,
    quantity: number,
    recurring: boolean
  ) => {
    if (!code) return;
    const petData = account?.pets.find((pet) => pet.id === petId);
    if (petData) {
      const snack = recipes?.find((recipe) =>
        recurring ? recipe.default_product?.code === code : recipe.one_time_product?.code === code
      );
      setIsUpdatingAddons(true);
      try {
        if (recurring) {
          await addAddon({
            petPlanId: petData.pet_plan.id,
            products: [{ code, quantity: quantity + 1, recurring: true }],
          });

          await refetchUser();
          displayToast({
            message: `${snack?.name} have been added to ${petData.name}'s plan.`,
            type: ToastType.Success,
            onPress: () => navigation.push('Dogs', { petId, scrollToTop: true }),
          });
        } else {
          await updateOrderProducts({
            productCode: code,
            mode: 'increase',
          });

          await refetchUser();
          const orderId = getNextOrderIdWithProduct(code, account);
          displayToast({
            message: `We've added ${snack?.name} to your next order.`,
            type: ToastType.Success,
            onPress: () => navigation.push('Orders', { orderId }),
          });
        }
        setIsPDPOpen(false);
      } catch (err) {
        displayToast({
          message: `An error occurred while adding ${snack?.name} to ${petData.name}'s plan.`,
          type: ToastType.Error,
        });
        logger.error(`Error adding ${snack?.name} to ${petData.name}'s plan: ${err}`);
      } finally {
        setIsUpdatingAddons(false);
      }
    }
  };

  const recipePillContent: { [key: RecipeFull['id']]: string } = {
    'SN-BEEFTRAINERS': 'Puppy Favorite',
    'SN-CHICKENBITES': 'Best Seller',
    'SN-CHEESEBITES': IS_CHEESEBITES_LAUNCHED === 'true' ? 'New' : '',
    'SN-TURKEYSTRIPS': IS_CHEESEBITES_LAUNCHED === 'true' ? '' : 'New',
    'DC-WASTEBAGS': 'New',
  };

  const getRecipeUnit = (recipe: RecipeFull) =>
    ADDONS_CONFIGURATION[recipe.type as AddonsType]?.unit;

  const isV2Eligible = localStorage.getItem('PRODUCT_PLATFORM_V2') === 'true';

  return (
    <VToHStack
      w="100%"
      space={{ base: 0, lg: 6 }}
      justifyContent={{ base: 'center', lg: 'start' }}
      flexWrap="wrap"
    >
      {recipes?.map((recipe, i) => {
        const unit = getRecipeUnit(recipe);
        const quantity = recipeQuantities ? recipeQuantities[recipe.id] : undefined;
        const firstPetWithRecipe = getFirstPetWithAddon(account, recipe.id);
        const onAdd = () => {
          segment.trackEvent('Add-On Subscription Initiated', {
            email: account.email,
            product_code: recipe.default_product.code,
            account_id: account.id,
          });
          setSelectedRecipe(recipe);
          if (activePets.length > 1) {
            setIsDogSelectOpen(true);
          } else if (activePets?.length) {
            handleAdd(recipe.default_product.code, activePets[0].id, quantity ? quantity : 0, true);
          }
        };

        const displayQuantity = displayRecipeQuantities?.[recipe.id];

        const orderId =
          getNextOrderIdWithProduct(recipe.default_product.code, account) ||
          getNextOrderIdWithProduct(recipe.one_time_product?.code || '', account);

        const itemRef =
          bannerRef?.recipeId === recipe.id && bannerRef?.ref ? bannerRef.ref : undefined;

        const imageExt = recipe.type === RecipeType.DC ? 'webp' : 'png';
        const imageUri = `${ASSETS_URL}/pictos/${capitalize(recipe.type)}/${recipe.id}.${imageExt}`;
        const hoverImageUri = `${ASSETS_URL}/pictos/${capitalize(recipe.type)}/${
          recipe.id
        }-HS.${imageExt}`;
        return (
          <Stack key={recipe.id + i.toString()}>
            {i === recipes.length - 1 && isMobile ? <Box ref={itemsRef} w={0} h={0} /> : null}
            <ProductListingItem
              ref={itemRef}
              h="fit-content"
              maxW="100%"
              mb={{ base: 9, lg: 12 }}
              unit={unit}
              title={recipe.name}
              onPressImage={() => onPressAddOn(recipe)}
              price={recipe.default_product.price.price}
              image={imageUri}
              quantity={isV2Eligible ? displayQuantity : quantity}
              imageProps={{ w: '100%', h: '100%' }}
              primaryCtaProps={{
                maxW: '240px',
                isDisabled: isUpdatingAddons && selectedRecipe?.id !== recipe.id,
                isLoading: isUpdatingAddons && selectedRecipe?.id === recipe.id,
              }}
              pressableProps={{
                w: { base: '100%', lg: '326px' },
                h: { base: '343px', lg: '326px' },
              }}
              isLongPressAvailable
              hoverStateImage={hoverImageUri}
              badgeMessage={
                recipePillContent[recipe.id] ? (
                  <StarBadge badgeContent={recipePillContent[recipe.id]} />
                ) : null
              }
              onAdd={onAdd}
              onEditQuantity={() =>
                isV2Eligible
                  ? navigation.push('Orders', { orderId })
                  : navigation.push('Dogs', { petId: firstPetWithRecipe, scrollToTop: true })
              }
              oneTimePrice={recipe.one_time_product?.price.price}
              retailPrice={recipe.one_time_product?.retail_price?.toString()}
              showTapForDetails
            />
          </Stack>
        );
      })}
      {selectedRecipe ? (
        <ShopPDPModal
          recipe={selectedRecipe}
          buttonText="ADD TO PLAN"
          showCTA
          productPrice={generatePriceObject(selectedRecipe)}
          prices={[selectedRecipe.default_product?.price.price]}
          pricingUnits={[getRecipeUnit(selectedRecipe)]}
          handleAddRecurring={() => {
            segment.trackEvent('Add-On Subscription Initiated', {
              email: account.email,
              product_code: selectedRecipe.default_product.code,
              account_id: account.id,
            });
            if (activePets?.length > 1) {
              setIsDogSelectOpen(true);
            } else {
              handleAdd(
                selectedRecipe.default_product?.code,
                activePets[0]?.id,
                recipeQuantities?.[selectedRecipe.id] ?? 0,
                true
              );
            }
          }}
          handleAddOneTime={() =>
            handleAdd(
              selectedRecipe.one_time_product?.code,
              activePets[0]?.id,
              recipeQuantities?.[selectedRecipe.id] ?? 0,
              false
            )
          }
          isOpen={isPDPOpen}
          onClose={() => setIsPDPOpen(false)}
          isLoading={isUpdatingAddons}
        />
      ) : null}
      {selectedRecipe ? (
        <DogSelectModal
          isOpen={isDogSelectOpen}
          onClose={() => {
            setIsDogSelectOpen(false);
          }}
          onSuccess={() => {
            setIsDogSelectOpen(false);
            setIsPDPOpen(false);
          }}
          recipe={selectedRecipe}
          petIds={activePets.map((pet) => pet.id)}
          onConfirm={addAddon}
          isLoading={isUpdatingAddons}
          showQuantityByPet
        />
      ) : null}
    </VToHStack>
  );
};
